
import {
	QUALIFICATIONS_CLEAR,
	QUALIFICATIONS_LOADED,
	QUALIFICATIONS_LOADING,
	QUALIFICATIONS_NEW_ITEM,
	QUALIFICATIONS_UPDATE_ITEM,
	QUALIFICATIONS_REMOVE_ITEM,
} from '../constants'

const initialState = {
	loading: false,
	list: [],
}


export default function Qualification(state = initialState, action) {
	switch(action.type) {

		case QUALIFICATIONS_LOADING:
			return {
				...state,
				loading: action.data
			}

		case QUALIFICATIONS_LOADED:
			return {
				...state,
				loading: false,
				list: action.data || []
			}

		case QUALIFICATIONS_UPDATE_ITEM:
			return {
				...state,
				loading: false,
				list: state.list.map( i => i.objectId === action.data.objectId  ?  action.data  : i ) 
			}
			
	
		case QUALIFICATIONS_REMOVE_ITEM:
			return {
				...state,
				list: state.list.filter( i => i.objectId !== action.data ) 
			}
			
		case QUALIFICATIONS_NEW_ITEM:
			return {
				...state,
				loading: false,
				list: [action.data, ...state.list],
			}

	
		case QUALIFICATIONS_CLEAR:
			return initialState  

		default:
			return state
	}
}