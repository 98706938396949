import {
	CANDIDATES_CLEAR,
	CANDIDATES_LOADED,
	CANDIDATES_UPDATE,
	CANDIDATES_LOADING,
	CANDIDATES_NEW_ITEM,
	CANDIDATES_UPDATE_ITEM,
	CANDIDATES_REMOVE_ITEM,
	CANDIDATES_LOADED_ALL,
	CANDIDATES_LOADED_MORE,
	CANDIDATES_CLEAR_FILTER,
	CANDIDATES_UPDATE_FILTER,
	CANDIDATES_ADD_QUALIFICATION
} from '../constants'

const initialState = {
	showModal: false,
	loading: false,
	list: [],
	filter: {
		from : null,
		to: null,
		qualifications: [],
		field: 'firstName',
		criteria: '',
		tab: 'search',
		isFilter: false
	},
}

export default function Candidates(state = initialState, action) {
	switch( action.type ) {

		case CANDIDATES_UPDATE:
			return {
				...state,
				...action.data
			}

		case CANDIDATES_LOADING:
			return {
				...state,
				loading: action.data
			}	

		case CANDIDATES_LOADED:
			return {
				...state,
				loading: false,
				list: action.data || []
			}


		case CANDIDATES_UPDATE_ITEM:
			return {
				...state,
				loading: false,
				list: state.list.map( i => i.objectId === action.data.objectId  ?  ({...i, ...action.data})  : i ) 
			}

		case CANDIDATES_ADD_QUALIFICATION:
			return {
				...state,
				list: state.list.map( item => {

					if (item.objectId !== action.data.objectId)
						return item
					
					item.qualifications = [...item.qualifications, action.data.qualification]
					console.log(item)
					return item
				
				})

			}	
			
	
		case CANDIDATES_REMOVE_ITEM:
			return {
				...state,
				list: state.list.filter( i => i.objectId !== action.data ) 
		}
			
		case CANDIDATES_NEW_ITEM:
			return {
				...state,
				loading: false,
				list: [action.data, ...state.list],
			}

		case CANDIDATES_LOADED_MORE:
			return {
				...state,
				loading:false,
				list:[ ...state.list, ...action.data ]
			}	

		case CANDIDATES_LOADED_ALL:
			return {
				...state,
				loading: false,
				noMoreToLoad: true
			}
			
		case CANDIDATES_UPDATE_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					...action.data
				}
			}
		
		case CANDIDATES_CLEAR_FILTER:
			return {
				...state,
				filter: {
					...initialState.filter,
					tab: state.filter.tab
				}
			}	

	
		case CANDIDATES_CLEAR:
			return {
				...initialState,
				filter: state.filter
			}  

		default:
			return state
	}
}