/************************************************
 * IMPORTS
 ***********************************************/

 import axios from 'axios'
 import {auth, storage} from './firebase'
 import {toast} from 'react-toastify'
 import {createBrowserHistory} from 'history' 
 import classnames from 'classnames'
 
 import {
	 API_URL, 
	 MIME_TYPES,
	 STORAGE_ERRORS
 } from '../constants'
 
 
 /************************************************
	* Create browser history
	***********************************************/
 
 export const history = createBrowserHistory()
 

  
/************************************************
* Format phone number
***********************************************/
export const formatPhone  = str => {

	if (!str.length)
		return ''

	if ( str.length === 10 )
		return `(${str.slice(0, 4)}) ${str.slice(4, 7)} ${str.slice(7, 10)}`

	return `${str.slice(0, 3)} (${str.slice(3, 6)}) ${str.slice(6, 9)} ${str.slice(9, 12)}`

} 


 /************************************************
 * Method to capitalize each word in a string
 * @param {string} str Text to capitalize
 ************************************************/
 export const toTitleCase = str => {
	return str.replace(/\w\S*/g, function(txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	})
}


 /************************************************
 * Method to split name in first and last
 * @param {string} str Text to split
 ************************************************/
	export const splitName = str => {

		let split = str.split(' ')

		let firstName = split[0]
		split.splice(0, 1)

		return [firstName, split.join(' ')]

	}

 
 /************************************************
	* XHR Request
	* @param {string} url URL where the request is performed
	* @param {object} data Additional request data
	* @param {boolean} needsAuth Should request send authorization headers
	***********************************************/
 
 export const request = async (url, data = {}, needsAuth = true) => {
 
	 // init optons
	 let options = {}
	 
	 if (needsAuth) {
 
		 // get currnet user
		 const {currentUser} = auth
 
		 // check if user is auth
		 if (!currentUser)
			 return {error: 'not_authenticated'}
 
		 // set token
		 options.headers = {Authorization: await currentUser.getIdToken()}
 
	 }
 
	 // triger the request
	 let response = await axios.post(API_URL + url, data, options)
		 .then(response => response.data)
		 .catch(() => ({error: 'api_failed'}))
 
	 // check response it's typeof object
	 if (typeof response !== 'object')
		 response = {error: 'api_failed'}
 
	 return response
 
 }

 /************************************************
	* Format to local currency
	***********************************************/
	export const formatToLocalCurrency = value => {

		var formatter =  new Intl.NumberFormat('en-UK', {
			style: 'currency',
			currency: 'GBP',
		})
	
		return formatter.format(value)
	
	 } 
 
 /************************************************
	* Method for triming text by length
	* @param {string} text
	* @param {number} maxLength 
	***********************************************/
 
export const trimText = ( text, maxLength ) => {

	let trimText = text.substring( 0, maxLength )

	if ( text.length > maxLength )
		trimText += '...'

	return trimText
}	


/************************************************
* Method displaying message
***********************************************/

export const showMessage = (message, type = 'error') => {

	toast[type](message, {
		autoClose: 2500,
		draggable: false,
		closeOnClick: true,
		pauseOnHover: true
	})

	return null
	
}

/************************************************
* Method displaying promomise message
***********************************************/

export const showPromiseMessage = (func, success = 'Succesfuly', error ='Oops, something wrong', pending = 'Loading, please wait...') => {

	toast.promise(
		func,
		{
			
			pending: pending,
			success:  success,
			error: {
				render({data}){
					return data || error
				}
			}
		}
	)

	return null
	
}


/************************************************
	* Method for converting
	***********************************************/

export const zeroPad = ( number, length = 2 ) => {
	return ( Array( length ).join('0') + number ).slice( -length )
}

/************************************************
	* Method for split phrase in half
	***********************************************/
	
export const splitTextInHalf = text => {

	var middle = Math.floor(text.length / 2);
	var before = text.lastIndexOf(' ', middle);
	var after = text.indexOf(' ', middle + 1);

	if (middle - before < after - middle) {
			middle = before;
	} else {
			middle = after;
	}

	return [
		text.substr(0, middle),
		text.substr(middle + 1)
	]

}


export const generateSortClass = (name, cirteria, type) => classnames({
	'fad fa-sort': cirteria !== name,
	'fad fa-sort-up': type === 'asc' && cirteria === name,
	'fad fa-sort-down': type === 'desc' && cirteria === name
})

/************************************************
* Method for saving image
***********************************************/
/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
 export const  getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {

  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  )
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  )

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file))
    }, 'image/png')
  })
}


const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}



/************************************************
	* Method for uploading file to storage
	* @param {string} uid
	* @param {file} file
	* @param {string} path
	* @param {string} filename
	* @param {boolean} listener
	***********************************************/
	export const upload = async ( uid, file, path, filename, listener = false ) => {

		if ( !file ) 
			return null
	
		let fileRef = storage.ref( uid )

		if ( path )
			fileRef = fileRef.child( path )
	
		fileRef = fileRef.child( `${filename}.${MIME_TYPES[ file.type ]}` )


			
		if ( listener )
		{
				
			const uploadTask = fileRef.put( file, { contentType: file.type })
	
			uploadTask.on('state_changed', snapshot => {
				listener({ 
					state: snapshot.state, 
					progress: ( ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100 ).toFixed(2)
				})
			}, error => {
				listener({ 
					state: 'error', 
					error: STORAGE_ERRORS[ error.code ] || error.code 
				})
			}, async () => {
				listener({ 
					state: 'finished',
					url: await uploadTask.snapshot.ref.getDownloadURL()
				})
			})
			
		} else {
			await fileRef.put( file, { contentType: file.type })
			return fileRef.getDownloadURL()
		}
		
	}

	export function prefixSuccessor(prefix) {

		let limit = prefix;
		
		while ( limit.length > 0 ) {
	
			const index = limit.length - 1;
			
			if ( limit[index] === '\xff' )
				limit = limit.slice(0, -1)
			else {
				limit = limit.substr(0, index) + String.fromCharCode(limit.charCodeAt(index) + 1)
				break
			}
			
		}
		
		return limit;
		
	}