/************************************************
 * IMPORTS
 ***********************************************/

import React,{useEffect} from 'react'
import ReactDOM from 'react-dom'
import {Router, Route, Switch, Redirect} from 'react-router-dom'
import {Provider, useDispatch, useSelector} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import {history} from './utils'
import store from './reducers'

import {
	detectLoginStatus, 
} from './actions/user'

/************************************************
* STYLES
***********************************************/

import 'react-toastify/dist/ReactToastify.min.css'
import "react-datepicker/dist/react-datepicker.css"


/************************************************
* COMPONENTS
***********************************************/

import TopBar from './components/TopBar'
import SideBar from './components/SideBar'
import Filter from './components/Filter'

import Home from './components/Home'
import Conenct from './components/Connect'
import Candidates from './components/Candidates'
import Qualifications from './components/Qualifications'



/************************************************
* INIT
***********************************************/


const App = () => {

	const dispatch = useDispatch()

const user = useSelector(state => state.user)

	useEffect(() => {
		dispatch(detectLoginStatus())
	},[dispatch])

 if(!user.objectId)
	 return(
		 <div className="container-fluid py-3">
			 <div className="section content">

				 <Switch>
					 <Route exact path="/" component={Conenct} />
				 </Switch>
			
		 </div>
	 </div>
		
 )

	return (
		<div className="container-fluid py-3">

			<Redirect from="/index.html" to="/" />

			<div className="section content">

					<TopBar />

					<div className="row">

						<div className="col-12 col-md-3">
							<SideBar />
							<Route exact path="/candidates" component={Filter} />
						</div>

						<div className="col-12 col-md-9">
							<Switch>
								<Route exact path='/' component={Home} />
								<Route path="/candidates" component={Candidates} />
								<Route path='/qualifications' component={Qualifications} /> 

								{/* <Route path="/:id?" component={Card} /> */}
							</Switch>
						</div>

					</div>
		
			</div>

		</div>
	)
}



/************************************************
* Render the application
***********************************************/

ReactDOM.render(
	<Router history={history}>
		<Provider store={store}>
			<App />
			<ToastContainer/>
		</Provider>
	</Router>,
	document.getElementById('app')
)