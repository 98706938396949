
import {
	PAYMENTS_CLEAR,
	PAYMENTS_LOADED,
	PAYMENTS_LOADING,
	PAYMENTS_NEW_ITEM,
	PAYMENTS_UPDATE_ITEM,
	PAYMENTS_REMOVE_ITEM,
} from '../constants'

const initialState = {
	loading: false,
	list: [],
}


export default function Payments(state = initialState, action) {
	switch(action.type) {

		case PAYMENTS_LOADING:
			return {
				...state,
				loading: action.data
			}

		case PAYMENTS_LOADED:
			return {
				...state,
				loading: false,
				list: action.data || []
			}

		case PAYMENTS_UPDATE_ITEM:
			return {
				...state,
				loading: false,
				list: state.list.map( i => i.objectId === action.data.objectId  ?  ({...i, ...action.data})  : i ) 
			}
			
	
		case PAYMENTS_REMOVE_ITEM:
			return {
				...state,
				list: state.list.filter( i => i.objectId !== action.data ) 
			}
			
		case PAYMENTS_NEW_ITEM:
			return {
				...state,
				loading: false,
				list: [action.data, ...state.list],
			}

	
		case PAYMENTS_CLEAR:
			return initialState  

		default:
			return state
	}
}