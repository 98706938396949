import React from 'react'
import {NavLink} from 'react-router-dom'

export default function SideBar(props) {


	return(
		<ul className="list-group mb-3 side-bar">

			<NavLink className="list-group-item" exact to="/">
				<i className="fad fa-chart-line" /> Dashboard
			</NavLink>

			<NavLink className="list-group-item" to="/candidates">
				<i className="fad fa-users" /> Candidates
			</NavLink>

			<NavLink className="list-group-item" to="/qualifications">
				<i className="fad fa-tools" /> Qualifications
			</NavLink>

		</ul>
	)
}