import React,{useEffect, useRef, useState} from 'react'
import {CANDIDATES_CLEAR_FILTER, SEARCH_FIELDS, STATUS} from '../../constants'
import {useDispatch, useSelector} from 'react-redux'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import ReactExport from "react-export-excel"

import { 
	fetchCandidates,
	updateCAndidatesFitler
} from '../../actions/user'
import moment from 'moment'

export default function Filter(props) {

	const selectRef = useRef()
	const dispatch = useDispatch()
	const candidates = useSelector(state => state.candidates)
	const qualifications = useSelector(state => state.qualifications)

	const [exprt, setExport] = useState([])


	const ExcelFile = ReactExport.ExcelFile
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

	const updateFilter = data => dispatch(updateCAndidatesFitler(data))

	const onChange = e => updateFilter({[e.target.name]: e.target.value})


	const onFilter = () => {

		updateFilter({isFilter: true})
		dispatch(fetchCandidates(true))

	}


	const onClean = () => {

		dispatch({type: CANDIDATES_CLEAR_FILTER})
		dispatch(fetchCandidates(true))
	
		if (selectRef.current)
			selectRef.current.clearValue()

	}

	const onKeyDown = e => {

		if (e.which !== 13) 
			return false

		return onFilter()

	}

	useEffect(() => {
		
		let listOfCandidates = candidates.list.map( item => {


			let listOfQualifications = item.qualifications.map(qualification => {
				let find = qualifications.list.find(i => i.objectId === qualification) || {}

				return find.title
			})


			return {
				firstName: item.firstName,
				middleName: item.middleName,
				lastName: item.lastName,
				email: item.email || '',
				phone: item.phone || '',
				qualifications: listOfQualifications.join(' , '),
				createdAt: moment(item.createdAt.toMillis()).format('DD MMM YYYY')
			}
			
		})

		setExport(listOfCandidates)

	},[candidates.list, qualifications.list])

	return(
		<>

			<ul className="nav nav-tabs">
				<li className='nav-item' onClick={() => updateFilter({tab: 'search'})}>
					<div className={`nav-link ${candidates.filter.tab === 'search' ? 'active' : ''}`}>Search</div>
				</li>
				<li className='nav-item' onClick={() => updateFilter({tab: 'filter'})}>
				<div className={`nav-link ${candidates.filter.tab === 'filter' ? 'active' : ''}`}>Filter</div>
				</li>
			</ul>

			{candidates.filter.tab === 'search' && 
				<div className="box p-2 mb-3 no-top">
					
					<div className="group mb-3">

						<label>Field</label>

						<select
							className="form-control"
							name="field"
							value={candidates.filter.field}
							onChange={onChange}
						>
							{Object.keys(SEARCH_FIELDS).map(key => <option key={key} value={key}>{SEARCH_FIELDS[key]}</option>)}
						</select>

					</div>

					<div className="group mb-4">

						<label>Search</label>
						
						{candidates.filter.field === 'clientStatus' ?
							<select
								className="form-control"
								name="criteria"
								value={candidates.filter.criteria}
								onChange={onChange}
							>
								<option value="">Select</option>
								{Object.keys(STATUS).map(key => <option key={key} value={key}>{STATUS[key]}</option>)}
							</select>
						:
							<input 
								className="form-control"
								name="criteria"
								value={candidates.filter.criteria}
								onChange={onChange}
								onKeyDown={onKeyDown}
							/>
						}
					
					</div>	

					<button className="btn btn-success fluid mb-2" disabled={!candidates.filter.criteria || candidates.loading} onClick={onFilter}>
						{candidates.loading ? <span className="spinner-border spinner-border-sm" /> : 'Search'}
					</button>
					
					{candidates.filter.isFilter &&
						<button className="btn btn-danger fluid mb-2" onClick={onClean} disabled={candidates.loading}>
							{candidates.loading ? <span className="spinner-border spinner-border-sm" /> : 'Clear'}
						</button>
					}

				</div>
			}


			{candidates.filter.tab === 'filter' &&

				<div className="box no-top p-2">

					<label>From</label>
					<div className="input-group mb-3 no-wrap">
						<DatePicker 
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							name="from"
							selected={candidates.filter.from} 
							maxDate={candidates.filter.to}
							format="DD/MM/YYYY"
							dateFormat="dd MMM yyyy"
							customInput={<input className="form-control no-round"/>}
							onChange={(date) => updateFilter({from: date})} 
						/>
						<button className="btn btn-danger" onClick={() => updateFilter({from: null})}>
							Clear
						</button>
					</div>

					<label>To</label>
					<div className="input-group mb-3 no-wrap">
				
						<DatePicker 
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							minDate={candidates.filter.from} 
							selected={candidates.filter.to}
							format="DD/MM/YYYY"
							dateFormat="dd MMM yyyy"
							customInput={<input className="form-control no-round" />}
							onChange={(date) => updateFilter({to: date})} 
						/>

						<button className="btn btn-danger" onClick={() => updateFilter({to: null})}>
							Clear
						</button>

					</div>

					<label>Qualification</label>
					<div className="input-group mb-3 no-wrap">
						<Select 
							options={qualifications.list.map(item => ({value: item.objectId, label: item.title}))}
							onChange={e => updateFilter({qualifications: e})}
							isClearable
							isMulti
							name="colors"
							className="basic-multi-select fluid no-round"
							classNamePrefix="select"
							ref={selectRef}
						/>
					</div>

					<button 
						className="btn btn-success fluid mb-2" 
						disabled={(!candidates.filter.qualifications.length && candidates.filter.from === null && candidates.filter.to === null )|| candidates.loading} 
						onClick={onFilter}
					>
						{candidates.loading ? <span className="spinner-border spinner-border-sm" /> : 'Filter'}
					</button>
					
					{candidates.filter.isFilter &&
						<button className="btn btn-danger fluid mb-2" onClick={onClean} disabled={candidates.loading}>
							{candidates.loading ? <span className="spinner-border spinner-border-sm" /> : 'Clear'}
						</button>
					}
		
				</div>

			}

			<div className="box p-2">
				<ExcelFile element={<button className="btn btn-success fluid" disabled={!candidates.list.length}>Export Excel</button>}>
					<ExcelSheet data={exprt} name="Candidates">
							<ExcelColumn label="First Name" value="firstName"/>
							<ExcelColumn label="Last Name" value="lastName"/>
							<ExcelColumn label="Email" value="email"/>
							<ExcelColumn label="Phone" value="phone"/>
							<ExcelColumn label="Qualifications" value="qualifications"/>
							<ExcelColumn label="CreatedAt" value="createdAt"/>
						
					</ExcelSheet>
				</ExcelFile>
			</div>
		</>
	)

}