import React,{useEffect, useState} from 'react'

export default function Modal(props) {

	const [blur, setBlur] = useState(false)
	const [modal, setModal] = useState(false)

	const onShowAnimation = () => {
		setTimeout(() => {
			setBlur(true)
			setTimeout(() => setModal(true), 300 )
		}, 100)
	}

	const onHideAnimation = () => {
			setModal(false)
			setBlur(false)
			setTimeout(() => props.onClose(), 300 )

	}

	useEffect(() => {
		if (props.show) 
			onShowAnimation()
		// eslint-disable-next-line 	
	},[props.show])

	return (
		<div className={`modal ${props.show ? 'show': 'hide'}`}>
			<div className={`blur-wrapper ${blur ? 'show' : 'hide'}`}>

				<div className={`modal-box ${modal ? 'show' : 'hide'}`}>

					<i className="far fa-times" onClick={onHideAnimation} />
					
					{props.children}
				</div>

			</div>
		</div>
	)
}