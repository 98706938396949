import React from 'react'
import {NavLink} from 'react-router-dom'
import {Switch, Route} from 'react-router'
import {useHistory} from 'react-router'

import List from './List'
import Profile from './Profile'
import Create from './Create'


export default function Candidates(props) {

	const history = useHistory()

	return(
		<>
			{['/candidates' , '/candidates/create'].includes(history.location.pathname) ?
				<ul className="nav nav-tabs">
					<li className="nav-item">
						<NavLink className="nav-link" exact to="/candidates">List</NavLink>
					</li>
					<li className="nav-item">
						<NavLink className="nav-link" to="/candidates/create">Create</NavLink>
					</li>
				</ul>
			: null }	

			<Switch>
				<Route exact path="/candidates" component={List}/>
				<Route exact path="/candidates/create" component={Create}/>
				<Route path="/candidates/:pid" component={Profile}/>
			</Switch>

		</>
	)

}