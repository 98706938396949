import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'

import {
	updateCandidate
} from '../../../../actions/user'


export default function Additional(props) {

	const {pid} = useParams()
	const dispatch = useDispatch()

	const user 						= useSelector(state => state.user)
	const candidates 				= useSelector(state => state.candidates)

	const initalState = {
		objectId: '',
		nino: '',
		cscs: '',
		intermadiar: ''
	}

	const [state, changeState] = useState(initalState)

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onChange = e => setState({[e.target.name]: e.target.value})

	const onSubmit = async () => dispatch(updateCandidate(state))




	useEffect(() => {

		const candidate = candidates.list.find(item => item.objectId === pid) || {}

		if (!candidate.objectId)
			return false

		setState({
			objectId: candidate.objectId || '',
			nino: candidate.nino || '',
			cscs: candidate.cscs || '',
			intermediar: candidate.intermediar || ''
		})

	},[pid, candidates.list])



	return(
		<div className="box p-2 no-top">
			<div className="row p-2 players-form">


			<div className="col-12 col-md-12">
					<div className="input-group mb-3">
						<span className="input-group-text">Intermediar</span>
						<input 
							type="text" 
							className="form-control" 
							name="intermediar"
							value={state.intermediar}
							onChange={onChange}
						/>
					</div>
				</div>


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">NINO</span>
						<input 
							type="text" 
							className="form-control" 
							name="nino"
							value={state.nino}
							onChange={onChange}
						/>
					</div>
				</div>


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">CSCS</span>
						<input 
							type="text" 
							className="form-control" 
							name="cscs"
							value={state.cscs}
							onChange={onChange}
						/>
					</div>
				</div>


				<div className="col-12 text-end">
		
					<button onClick={onSubmit} className="btn btn-outline-success" disabled={user.loading}>
						{user.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'Save' }
					</button>
				</div>

			</div>
		</div>
	)

}