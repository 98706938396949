import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {updateQualification} from '../../../actions/user'

export default function Create(props) {

	const {qid} = useParams()
	const dispatch = useDispatch()

	const user = useSelector(state => state.user)
	const qualifications = useSelector(state => state.qualifications)

	const initalState = {
		id: '',
		price: '',
		title:'',
	}


	const [state, changeState] = useState(initalState)

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onChange = e => setState({[e.target.name]: e.target.value})

	const onSubmit = async () => {

		let response = await dispatch(updateQualification(state))

		if(response)
			setState(initalState)

	}

	useEffect(() => {

		const qualification = qualifications.list.find(item => item.objectId === qid) || {}

		if (!qualification.objectId)
			return false

		setState({
			id: qualification.objectId || '',
			price: qualification.price || '0',
			title: qualification.title || '',

		})

	},[qid, qualifications.list])

	return(
		<div className="row p-2">

			<div className="col-12 col-md-6">
				<div className="input-group mb-3">
					<span className="input-group-text">Title</span>
					<input 
						type="text" 
						className="form-control" 
						name="title"
						value={state.title}
						onChange={onChange}
					/>
				</div>
			</div>


			<div className="col-12 col-md-6">
				<div className="input-group mb-3">
					<span className="input-group-text">Price</span>
					<input 
						type="number" 
						className="form-control" 
						name="price"
						value={state.price}
						onChange={onChange}
					/>
				</div>
			</div>


			<div className="col-12 text-end">
				<button onClick={onSubmit} className="btn btn-success" disabled={user.loading}>
					{user.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'Update' }
				</button>
			</div>

		</div>
	)

}