import React,{useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'

import {
	fetchNotes,
	fetchPayments
} from '../../../actions/user'


import Personal from './Personal'
import Address from './Address'
import Additional from './Additional'
import Stats from './Status'
import Notes from './Notes'
import Qualifications from './Qualifications'



export default function Candidates(props) {

	const dispatch = useDispatch()
	const {pid} = useParams()

	const [tab, setTab] = useState('personal')

	useEffect(() => {
		dispatch(fetchNotes(true, 'userId', pid))
		dispatch(fetchPayments(pid))
	}, [dispatch, pid])

	return(
		<>
			<ul className="nav nav-tabs">

				<li className="nav-item" onClick={() => setTab('personal')}>
					<div className={`nav-link ${tab === 'personal' ? 'active' : ''}`} >Personal</div>
				</li>

				<li className="nav-item" onClick={() => setTab('address')}>
					<div className={`nav-link ${tab === 'address' ? 'active' : ''}`} >Address</div>
				</li>

				<li className="nav-item" onClick={() => setTab('additional')}>
					<div className={`nav-link ${tab === 'additional' ? 'active' : ''}`} >Additional</div>
				</li>

				<li className="nav-item" onClick={() => setTab('status')}>
					<div className={`nav-link ${tab === 'status' ? 'active' : ''}`}>Status</div>
				</li>

			</ul>

			{tab === 'personal' && <Personal />} 
			{tab === 'address' && <Address />}
			{tab === 'additional' && <Additional />}
			{tab === 'status' && <Stats />}


			<Qualifications />
			<Notes />
		</>
	)

}