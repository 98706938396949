import React from 'react'
import {NavLink} from 'react-router-dom'
import {Switch, Route} from 'react-router'

import List from './List'
import Edit from './Edit'
import Create from './Create'

export default function Qualifications(props) {

	return(
		<>
			<ul className="nav nav-tabs">
				<li className="nav-item">
					<NavLink className="nav-link" exact to="/qualifications">List</NavLink>
				</li>
				<li className="nav-item">
					<NavLink className="nav-link" to="/qualifications/create">Create</NavLink>
				</li>
			</ul>

			<div className="box p-2 no-top">
				<Switch>
					<Route exact path="/qualifications" component={List}/>
					<Route exact path="/qualifications/create" component={Create}/>
					<Route path="/qualifications/:qid" component={Edit}/>
				</Switch>
			</div>
		</>
	)

}