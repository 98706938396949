import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import DatePicker from "react-datepicker"

import {
	createCandidate
} from '../../../actions/user'


export default function Create(props) {

	const dispatch = useDispatch()

	const user = useSelector(state => state.user)

	const initalState = {
		firstName: '',
		middleName:'',
		lastName: '',
		email:'',
		phone: '',
		birthDate: ''
	}


	const [state, changeState] = useState(initalState)

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onChange = e => setState({[e.target.name]: e.target.value})

	const onSubmit = async () => {

	 dispatch(createCandidate(state))

	}


	return(
		<div className="box p-2 no-top">
			<div className="row p-2 players-form">


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">First Name</span>
						<input 
							type="text" 
							className="form-control" 
							name="firstName"
							value={state.firstName}
							onChange={onChange}
						/>
					</div>
				</div>


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Middle Name</span>
						<input 
							type="text" 
							className="form-control" 
							name="middleName"
							value={state.middleName}
							onChange={onChange}
						/>
					</div>
				</div>


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Last Name</span>
						<input 
							type="text" 
							className="form-control" 
							name="lastName"
							value={state.lastName}
							onChange={onChange}
						/>
					</div>
				</div>

				<div className="col-12 col-md-6">
					<div className="input-group mb-3 no-wrap">
						<span className="input-group-text">Birth date</span>
						<DatePicker 
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							selected={state.birthDate} 
							format="DD/MM/YYYY"
							dateFormat="dd MMM yyyy"
							customInput={<input className="form-control date-piker" />}
							onChange={(date) => setState({birthDate: date})} 
						/>
					</div>
				</div>


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Email</span>
						<input 
							type="text" 
							className="form-control" 
							name="email"
							value={state.email}
							onChange={onChange}
						/>
					</div>
				</div>


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Phone</span>
						<input 
							type="text" 
							className="form-control" 
							name="phone"
							value={state.phone}
							onChange={onChange}
						/>
					</div>
				</div>
		
		
				<div className="col-12 text-end">
					<button onClick={onSubmit} className="btn btn-success" disabled={user.loading}>
						{user.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'Create' }
					</button>
				</div>

			</div>
		</div>	
	)

}