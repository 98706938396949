import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'

import {
	updateCandidate
} from '../../../../actions/user'

import {
	STATUS
} from '../../../../constants'


export default function Status(props) {

	const {pid} = useParams()
	const dispatch = useDispatch()

	const user 						= useSelector(state => state.user)
	const candidates 				= useSelector(state => state.candidates)

	const initalState = {
		objectId: '',
		clientStatus: '',
		applicationStatus: '',
		documentStatus: '',
		photoStatus: '',
		videoStatus: '',
	}

	const [state, changeState] = useState(initalState)

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onChange = e => setState({[e.target.name]: e.target.value})

	const onSubmit = async () => dispatch(updateCandidate(state))




	useEffect(() => {

		const candidate = candidates.list.find(item => item.objectId === pid) || {}

		if (!candidate.objectId)
			return false

		setState({
			objectId: candidate.objectId || '',
			clientStatus: candidate.clientStatus || '',
			applicationStatus: candidate.applicationStatus || '',
			documentStatus: candidate.documentStatus || '',
			photoStatus: candidate.photoStatus || '',
			videoStatus: candidate.videoStatus || ''
		})

	},[pid, candidates.list])



	return(
		<div className="box p-2 no-top">
			<div className="row p-2 players-form">


				<div className="col-12 col-md-12">
					<div className="input-group mb-3">
						<span className="input-group-text">Client Status</span>
						<select 
							className="form-control" 
							name="clientStatus"
							value={state.clientStatus}
							onChange={onChange}
						>	
							<option value="">Select</option>
							{Object.keys(STATUS).map(key => <option key={key} value={key}>{STATUS[key]}</option>)}
						</select>	
					</div>
				</div>


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Application</span>
						<select 
							className="form-control" 
							name="applicationStatus"
							value={state.applicationStatus}
							onChange={onChange}
						>	
							<option value="">Select</option>
							{Object.keys(STATUS).map(key => <option key={key} value={key}>{STATUS[key]}</option>)}
						</select>	
					</div>
				</div>

				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Documents</span>
						<select 
							className="form-control" 
							name="documentStatus"
							value={state.documentStatus}
							onChange={onChange}
						>	
							<option value="">Select</option>
							{Object.keys(STATUS).map(key => <option key={key} value={key}>{STATUS[key]}</option>)}
						</select>	
					</div>
				</div>

				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Photos</span>
						<select 
							className="form-control" 
							name="photoStatus"
							value={state.photoStatus}
							onChange={onChange}
						>	
							<option value="">Select</option>
							{Object.keys(STATUS).map(key => <option key={key} value={key}>{STATUS[key]}</option>)}
						</select>	
					</div>
				</div>

				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Videos</span>
						<select 
							className="form-control" 
							name="videoStatus"
							value={state.videoStatus}
							onChange={onChange}
						>	
							<option value="">Select</option>
							{Object.keys(STATUS).map(key => <option key={key} value={key}>{STATUS[key]}</option>)}
						</select>	
					</div>
				</div>


				<div className="col-12 text-end">
		
					<button onClick={onSubmit} className="btn btn-outline-success" disabled={user.loading}>
						{user.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'Save' }
					</button>
				</div>

			</div>
		</div>
	)

}