import {
	USERS_CLEAR,
	USERS_LOADED,
	USERS_UPDATE,
	USERS_LOADING,
	USERS_NEW_ITEM,
} from '../constants'

const initialState = {
	showModal: false,
	loading: false,
	list: [],
}

export default function Users(state = initialState, action) {
	switch( action.type ) {

		case USERS_UPDATE:
			return {
				...state,
				...action.data
			}

		case USERS_LOADING:
			return {
				...state,
				loading: action.data
			}	

		case USERS_LOADED:
			return {
				...state,
				loading: false,
				list: action.data || []
			}
			
			
		case USERS_NEW_ITEM:
			return {
				...state,
				loading: false,
				list: [action.data, ...state.list],
			}

		case USERS_CLEAR:
			return initialState

		default:
			return state
	}
}