/************************************************
* IMPORTS
 ************************************************/
import {
	serverTime,
	auth, 
	db,
	fieldValue,
} from '../utils/firebase'

import {
	showPromiseMessage,
	showMessage,
	prefixSuccessor,
	history,
	toTitleCase,
	formatToLocalCurrency
} from '../utils'

import {
	USER_UPDATE,
	USER_LOADING,
	USER_LOGGED_OUT,

	CANDIDATES,
	CANDIDATES_CLEAR,
	CANDIDATES_LOADING,
	CANDIDATES_PER_REQUEST,
	CANDIDATES_NEW_ITEM,
	CANDIDATES_LOADED_MORE,
	CANDIDATES_LOADED_ALL,
	CANDIDATES_LOADED,
	CANDIDATES_UPDATE_ITEM,
	CANDIDATES_ADD_QUALIFICATION,

	QUALIFICATIONS,
	QUALIFICATIONS_LOADED,
	QUALIFICATIONS_LOADING,
	QUALIFICATIONS_UPDATE_ITEM,
	QUALIFICATIONS_NEW_ITEM,

	NOTES,
	NOTES_CLEAR,
	NOTES_LOADING,
	NOTES_PER_REQUEST,
	NOTES_NEW_ITEM,
	NOTES_LOADED_MORE,
	NOTES_LOADED_ALL,
	NOTES_LOADED,
	NOTES_UPDATE_ITEM,


	ORDERS,
	ORDERS_LOADED,
	ORDERS_LOADING,
	ORDERS_UPDATE_ITEM,
	ORDERS_NEW_ITEM,

	PAYMENTS,
	PAYMENTS_LOADED,
	PAYMENTS_LOADING,
	PAYMENTS_NEW_ITEM,
	CANDIDATES_UPDATE_FILTER,

	USERS,
	USERS_LOADING,
	USERS_LOADED,
	USERS_NEW_ITEM,
	
} from '../constants'

import moment from 'moment'

/************************************************
* CONSTANTS
 ************************************************/

const store = {
	users: db.collection(USERS),
	notes: db.collection(NOTES),
	orders: db.collection(ORDERS),
	payments: db.collection(PAYMENTS),
	candidates: db.collection(CANDIDATES),
	qualifications: db.collection(QUALIFICATIONS)
}

const unsubscribe = {}


/************************************************
* Method for update user redux
* @param {object} data 
************************************************/

export const updateUserData = data => dispatch => dispatch({type: USER_UPDATE, data: data})

/************************************************
* Method for update candidates filter redux
* @param {object} data 
***********************************************/
export const updateCAndidatesFitler = data => dispatch => dispatch({type: CANDIDATES_UPDATE_FILTER, data: data})


/************************************************
* Method for change loading state
* @param {object} data 
************************************************/

export const setLoading = data => dispatch => dispatch({type: USER_LOADING, data: data})


/************************************************
* Method for checking user auth
* @param {object} data 
************************************************/

export const checkUserAuth = () => {
	
 	// get uid
	 const {uid} = auth.currentUser || {}

	 // check if user is auth
	return uid ? true : false

}




/************************************************
* Method for triming text by length
* @param {string} text
* @param {number} maxLength 
************************************************/

export const detectLoginStatus = () => async (dispatch, getState) => {

	//listen for account state change
	auth.onAuthStateChanged( async account => {

		// get user 
		const {user} = getState()

		// check if isNew
		if (user.isNew)
			return false

		// start loading
		dispatch(updateUserData({loading: true, description: ''}))
	
		// check if connection exit
		if (!account)
			return dispatch({type: USER_LOGGED_OUT})

		// await dispatch(migrateCandidates())
		await dispatch(fetchUsers())
		await dispatch(fetchCandidates(true))
		await dispatch(fetchQualifications())
		// await dispatch(fetchNotes(true))	

		// add data to redux
		dispatch(updateUserData({objectId: account.uid, loading: false}))

		// dispatch(test())

	})

}


/************************************************
* Method for login 
* @param {string} email
* @param {number} password 
************************************************/

export const login = (email, password) => ( dispatch, getState ) => {

	// get user
	let {user} = getState()

	// check if loading
	if (user.loading)
		return false

	// clean white spaces
	email = email.trim()
	password = password.trim()

	// check if email and passsord are not empty
	if (!email.length || !password.length)
		return showMessage('Please compelte the fields')

	// start loading
	dispatch(setLoading(true))
		
	// login user
	return auth.signInWithEmailAndPassword(email, password)
	.catch(({ message }) => showMessage(message))
	.finally(() => dispatch(dispatch(setLoading(false))))

}


/************************************************
* Method for logout
************************************************/

export const logout = () => async dispatch => {

	// check if user is auth
	if (!checkUserAuth())
		return false

	// unbind listeners
	Object.keys(unsubscribe).forEach(prop => {
		
		// unsubscribe
		unsubscribe[prop]()
		
		// remove 
		delete unsubscribe[prop]

	})

	history.push('/')

	// logout
	await	auth.signOut()


}


/************************************************
* Method for fatching candidates
************************************************/

export const fetchCandidates = (reload = false) => (dispatch, getState) => {

	// check if reload
	if (reload) {

		// unsubscribe
		if (unsubscribe.candidates) 
			unsubscribe.candidates()

		// clear candidates list
		dispatch({type: CANDIDATES_CLEAR})

	}

	// get candidates and user
	const {candidates} = getState()

	// check if it's loading or noMoreToLoad
	if (candidates.loading || candidates.noMoreToLoad) 
		return false
	
	const {filter} = candidates


	// start loading
	dispatch({
		type: CANDIDATES_LOADING,
		data: true
	})

	// create query
	let query = store.candidates

	const LIMIT_PER_REQUEST = filter.tab === 'search' ? CANDIDATES_PER_REQUEST : 1000


	if (filter.isFilter) {

		// SERACH
		if (filter.tab === 'search' && filter.field && filter.criteria) {

			if (filter.field === 'clientStatus')
				query = query.where(filter.field, '==', filter.criteria)
			else {

				let criteria = toTitleCase(filter.criteria)

				query = query
				.where(filter.field, '>=', criteria)
				.where(filter.field, '<', prefixSuccessor(criteria))
				.orderBy(filter.field, 'asc')
				
			}

		}	

		if (filter.tab === 'filter') {

			let qualifications = filter.qualifications.map(item => item.value)

			let start = filter.from ? moment(filter.from).startOf('day').toDate() : null
			let end = filter.to ? moment(filter.to).endOf('day').toDate() : null

			if (start !== null)
				query = query.where('createdAt', '>=', start)

			if (end !== null)	
				query = query.where('createdAt', '<=', end)

			if (qualifications.length)
				query = query.where('qualifications', 'array-contains-any', qualifications)
		
		}
	
	}

	query = query
	.orderBy('createdAt', 'desc' )

	// if list empty fech else load more
	if (!candidates.list.length) 

		query
		.limit(LIMIT_PER_REQUEST)
		.get()
		.then(snapshot => {

			if (!snapshot.empty)
				query = query.endBefore(snapshot.docs[0])

			unsubscribe.players = query.onSnapshot(snapshot =>
				snapshot.docChanges().forEach(change => {						
					if (change.type === 'added') 
						dispatch({
							type: CANDIDATES_NEW_ITEM,
							data: {...change.doc.data({ serverTimestamps: 'estimate' }), doc: change.doc}
						})
				})
			)

			// check if it'e empty
			if (snapshot.empty) 
				return dispatch({type: CANDIDATES_LOADED_ALL})

			let items = snapshot.docs.map(doc => ({...doc.data(), doc: doc}))

			dispatch({
				type: CANDIDATES_LOADED,
				data: items
			})

			// check if are more to load
			if (snapshot.docs.length < LIMIT_PER_REQUEST)
				dispatch({type: CANDIDATES_LOADED_ALL})
		})
		.catch(e => console.log(e.message))
		.finally(() => dispatch({type: CANDIDATES_LOADING, data: false}))
	
	else

		// load more lists
		query
		.limit(LIMIT_PER_REQUEST)
		.startAfter(candidates.list[candidates.list.length - 1].doc)
		.get()
		.then(snapshot => {
	
			// check if it'e empty
			if (snapshot.empty) 
				return dispatch({type: CANDIDATES_LOADED_ALL})

			// get data
			let items = snapshot.docs.map(doc => ({...doc.data(), doc: doc}))
			
			// add items to list
			dispatch({type: CANDIDATES_LOADED_MORE, data: items})

			// check if are more to load
			if (items.length < LIMIT_PER_REQUEST)
				dispatch({type: CANDIDATES_LOADED_ALL})

		})
		.catch(e => console.log(e.message))
		.finally(() => dispatch({type: CANDIDATES_LOADING, data: false}))


}


/************************************************
* Method for fatching candidates by id
************************************************/

export const fetchCandidatesById = id => (dispatch, getState) => {

	// get candidates and user
	const {candidates} = getState()

	// check if it's loading or noMoreToLoad
	if (candidates.loading || candidates.noMoreToLoad) 
		return false
	
	let index = candidates.list.findIndex(item => item.objectId === id)

	if (index > 0)
		return false

	// start loading
	dispatch({
		type: CANDIDATES_LOADING,
		data: true
	})

	// create query
	store.candidates.doc(id)
	.get()
	.then(snapshot => {

		let item = {...snapshot.data(), doc: snapshot}

		dispatch({
			type: CANDIDATES_NEW_ITEM,
			data: item
		})

	})
	.catch(e => console.log(e.message))
	.finally(() => dispatch({type: CANDIDATES_LOADING, data: false}))
	
	
}


/************************************************
* Method for fatching candidates
************************************************/

export const filterCandidates = (from, to, qualifications) => (dispatch, getState) => {

	const LIMIT = 1000

	// unsubscribe
	if (unsubscribe.candidates) 
		unsubscribe.candidates()

	// clear candidates list
	dispatch({type: CANDIDATES_CLEAR})

	
	// get candidates and user
	const {candidates} = getState()

	// check if it's loading or noMoreToLoad
	if (candidates.loading || candidates.noMoreToLoad) 
		return false
	

	// start loading
	dispatch({
		type: CANDIDATES_LOADING,
		data: true
	})

	// create query
	let query = store.candidates

	qualifications = qualifications.map(item => item.value)

	let start = from ? moment(from).startOf('day').toDate() : null
	let end = to ? moment(to).endOf('day').toDate() : null

	if (start !== null)
		query = query.where('createdAt', '>=', start)

	if (end !== null)	
		query = query.where('createdAt', '<=', end)

	if (qualifications.length)
		query = query.where('qualifications', 'array-contains-any', qualifications)
	

	query = query
	.orderBy('createdAt', 'desc' )

		// if list empty fech else load more
		if (!candidates.list.length) 

		query
		.limit(LIMIT)
		.get()
		.then(snapshot => {

			if (!snapshot.empty)
				query = query.endBefore(snapshot.docs[0])

			unsubscribe.players = query.onSnapshot(snapshot =>
				snapshot.docChanges().forEach(change => {						
					if (change.type === 'added') 
						dispatch({
							type: CANDIDATES_NEW_ITEM,
							data: change.doc.data({ serverTimestamps: 'estimate' })
						})
				})
			)

			// check if it'e empty
			if (snapshot.empty) 
				return dispatch({type: CANDIDATES_LOADED_ALL})

			let items = snapshot.docs.map(doc => doc.data())

			dispatch({
				type: CANDIDATES_LOADED,
				data: items
			})

			// check if are more to load
			if (snapshot.docs.length < LIMIT)
				dispatch({type: CANDIDATES_LOADED_ALL})
		})
		.catch(e => console.log(e.message))
		.finally(() => dispatch({type: CANDIDATES_LOADING, data: false}))
	
	else

		// load more lists
		query
		.limit(LIMIT)
		.startAfter(candidates.list[candidates.list.length - 1].createdAt)
		.get()
		.then(snapshot => {
	
			// check if it'e empty
			if (snapshot.empty) 
				return dispatch({type: CANDIDATES_LOADED_ALL})

			// get data
			let items = snapshot.docs.map(doc => doc.data())
			
			// add items to list
			dispatch({type: CANDIDATES_LOADED_MORE, data: items})

			// check if are more to load
			if (items.length < LIMIT)
				dispatch({type: CANDIDATES_LOADED_ALL})

		})
		.catch(e => console.log(e.message))
		.finally(() => dispatch({type: CANDIDATES_LOADING, data: false}))

}



/************************************************
* Method for create candidate
* @param {object} data
************************************************/

export const createCandidate = data => async (dispatch, getState) => {

	const {user} = getState()

	if (user.loading || !checkUserAuth())
		return false

	let firstName = data.firstName.trim()
	let lastName = data.lastName.trim()
	let middleName = data.middleName.trim()
	let birthDate	= moment(data.birthDate)
	let email = data.email.trim()
	let phone = data.phone.trim()


	if (!firstName || !lastName || !birthDate.isValid()) {
		 showMessage('First name, Last name and birth date must be filled in')
		 return false
	}

	dispatch(setLoading(true))

	let ref = store.candidates.doc()

	let payload = {
		createdAt: serverTime,
		objectId: ref.id,
		firstName: firstName,
		lastName: lastName,
		middleName: middleName,
		birthDate: birthDate.format('YYYY-MM-DD'),
		phone: phone,
		email: email,
		createdBy: auth.currentUser.uid,
		clientStatus: 'missing',
		applicationStatus: '',
		documentStatus: '',
		photoStatus: '',
		videoStatus: '',
		intermediar: '',
		address: '', 
		postCode: '',
		houseNo:  '',
		cscs: '',
		nino: '',
		qualifications: []
	}


	let process =  new Promise((resolve, reject) => ref.set(payload)
	.then(() => {

		resolve('Candidate created succesfuly')

		history.push('/candidates/' + ref.id)

	})
	.catch(err =>  reject(err.message)
	))

	await showPromiseMessage(process, 'Candidate created succesfuly')

	dispatch(setLoading(false))

	return true

}


/************************************************
* Method for create new qualification
* @param {object} data 
************************************************/

export const createQualification = data => async (dispatch, getState) => {

	const {user} = getState()

	if (user.loading || !checkUserAuth())
		return false

	let price = data.price.trim()
	let title = data.title.trim()

	if (isNaN(price)|| !title.length) {
		 showMessage('Price and title are empty')
		 return false
	}

	dispatch(setLoading(true))

	let ref =  store.qualifications.doc()
	
	let process =  new Promise((resolve, reject) => ref.set({
		objectId: ref.id,
		price: parseFloat(price),
		title,
		createdAt: serverTime,
	})
	.then(() => resolve('Qualification added succesfuly'))
	.catch(err =>  reject(err.message)
	))

	await showPromiseMessage(process, 'Qualification added succesfuly')

	dispatch(setLoading(false))

	return true

}


/************************************************
* Method for create candidate
* @param {object} data
************************************************/

export const updateCandidate = data => async (dispatch, getState) => {

	const {user} = getState()

	if (user.loading || !checkUserAuth() || !data.objectId)
		return false

	dispatch(setLoading(true))


	let ref = store.candidates.doc(data.objectId)


	let process =  new Promise((resolve, reject) => ref.update(data)
	.then(() => {

		resolve('Candidate update succesfuly')

		dispatch({
			type: CANDIDATES_UPDATE_ITEM,
			data: data
		})

	})
	.catch(err =>  reject(err.message)
	))

	await showPromiseMessage(process, 'Candidate update succesfuly')

	dispatch(setLoading(false))

	return true


}


/************************************************
* Method for create new qualification
* @param {object} data 
************************************************/

export const fetchQualifications = () => async (dispatch, getState) => {

	const {qualifications} = getState()

	if (qualifications.loading)
		return false

	dispatch({
		type: QUALIFICATIONS_LOADING,
		data: true
	})	

	let query = store.qualifications.orderBy('createdAt', 'desc')

	await query.get()
	.then(snapshot => {

		let items = snapshot.docs.map(item => item.data())

		dispatch({
			type: QUALIFICATIONS_LOADED,
			data: items
		})

		if (!snapshot.empty)
			query = query.endBefore(snapshot.docs[0])

			unsubscribe.qualifications = query.onSnapshot(snapshot =>
				snapshot.docChanges().forEach(change => {			

					if (change.type === 'added')
						dispatch({
							type: QUALIFICATIONS_NEW_ITEM,
							data: change.doc.data({ serverTimestamps: 'estimate'})
						})
				})

			)


	}).catch(e => showMessage(e.message))

	dispatch({
		type: QUALIFICATIONS_LOADING,
		data: false
	})	

}


/************************************************
* Method for update existing qualification
* @param {object} data 
************************************************/

export const updateQualification = data => async (dispatch, getState) => {

	const {user} = getState()

	if (user.loading || !checkUserAuth())
		return false

	let id = data.id.trim()
	let price = data.price.trim()
	let title = data.title.trim()


	if (!id.length || isNaN(price)|| !title.length) {
		 showMessage('Price and title must be fill')
		 return false
	}

	dispatch(setLoading(true))

	
	let process =  new Promise((resolve, reject) => store.qualifications.doc(id).update({
		objectId: id,
		price: price,
		title
	})
	.then(() => resolve('Qualification update succesfuly'))
	.catch(err =>  reject(err.message)
	))

	await showPromiseMessage(process, 'Qualification update succesfuly')

	dispatch(setLoading(false))

	dispatch({
		type: QUALIFICATIONS_UPDATE_ITEM,
		data: {
			objectId: id,
			price,
			title,
		}
	})

	return true

}


/************************************************
* Method for create new note
* @param {string} status 
* @param {string} message 
* @param {string} userId 
************************************************/

export const createNote = (status, message, userId) => async (dispatch, getState) => {

	const {user} = getState()

	if (user.loading || !checkUserAuth())
		return false

	message = message.trim()

	if (!message || !status || !userId) {
		 showMessage('Status and message must be filled in')
		 return false
	}

	dispatch(setLoading(true))

	let ref =  store.notes.doc()
	
	let process =  new Promise((resolve, reject) => ref.set({
		objectId: ref.id,
		status,
		message,
		userId,
		authorId: auth.currentUser.uid,
		createdAt: serverTime,
	})
	.then(() => resolve('Note added succesfuly'))
	.catch(err =>  reject(err.message)
	))

	await showPromiseMessage(process, 'Note added succesfuly')

	dispatch(setLoading(false))

	return true

}

/************************************************
* Method for create new note
* @param {string} objectId 
* @param {string} status 
* @param {string} message 
************************************************/

export const updateNote = (objectId, status, message) => async (dispatch, getState) => {

	const {user} = getState()

	if (user.loading || !checkUserAuth())
		return false

	message = message.trim()

	if (!message || !status || !objectId) {
		 showMessage('Status and message must be filled in')
		 return false
	}

	dispatch(setLoading(true))

	let ref =  store.notes.doc(objectId)

	let payload = {
		objectId,
		status,
		message,
		editedAt: serverTime,
		editedBy: auth.currentUser.uid
	}
	
	let process =  new Promise((resolve, reject) => ref.update(payload)
	.then(() => {
		resolve('Note update succesfuly')
		dispatch({
			type: NOTES_UPDATE_ITEM,
			data: payload
		})
	})
	.catch(err =>  reject(err.message)
	))

	await showPromiseMessage(process, 'Note update succesfuly')

	dispatch(setLoading(false))

	return true

}


/************************************************
* Method for fatching notes
************************************************/

export const fetchNotes = (reload = false, field = null , criteria = null) => (dispatch, getState) => {

	// check if reload
	if (reload) {

		// unsubscribe
		if (unsubscribe.notes) 
			unsubscribe.notes()

		delete unsubscribe.notes

		// clear notes list
		dispatch({type: NOTES_CLEAR})

	}

	// get notes and user
	const {notes, candidates} = getState()

	// check if it's loading or noMoreToLoad
	if (notes.loading || notes.noMoreToLoad) 
		return false
	

	// start loading
	dispatch({
		type: NOTES_LOADING,
		data: true
	})

	// create query
	let query = store.notes

	if (field && criteria) 
		query = query.where(field, '==', criteria)
	


	query = query
	.orderBy('createdAt', 'desc' )

	// if list empty fech else load more
	if (!notes.list.length) 

		query
		.limit(NOTES_PER_REQUEST)
		.get()
		.then(async snapshot => {

			if (!snapshot.empty)
				query = query.endBefore(snapshot.docs[0])

			unsubscribe.notes = query.onSnapshot(snapshot =>
				snapshot.docChanges().forEach(change => {	
						
					if (change.type === 'added') 
						dispatch({
							type: NOTES_NEW_ITEM,
							data: change.doc.data({ serverTimestamps: 'estimate' })
						})
				})
			)

			// check if it'e empty
			if (snapshot.empty) 
				return dispatch({type: NOTES_LOADED_ALL})

	
			let process = snapshot.docs.map(async doc => {

				let item = doc.data()

				let find = candidates.list.find(i => i.objectId === item.userId) || {}

				if (!find.objectId)
					dispatch(fetchCandidatesById(item.userId))

				return item	

			})

			let items = await Promise.all(process)
			
			dispatch({
				type: NOTES_LOADED,
				data: items
			})

			// check if are more to load
			if (snapshot.docs.length < NOTES_PER_REQUEST)
				dispatch({type: NOTES_LOADED_ALL})
		})
		.catch(e => console.log(e.message))
		.finally(() => dispatch({type: NOTES_LOADING, data: false}))
	
	else

		// load more lists
		query
		.limit(NOTES_PER_REQUEST)
		.startAfter(notes.list[notes.list.length - 1].createdAt)
		.get()
		.then(async snapshot => {

	
			// check if it'e empty
			if (snapshot.empty) 
				return dispatch({type: NOTES_LOADED_ALL})

			// get data
			let process = snapshot.docs.map(async doc => {

				let item = doc.data()

				let find = candidates.list.find(i => i.objectId === item.userId) || {}

				if (!find.objectId)
					dispatch(fetchCandidatesById(item.userId))

				return item	

			})

			let items = await Promise.all(process)
			
			// add items to list
			dispatch({type: NOTES_LOADED_MORE, data: items})

			// check if are more to load
			if (items.length < NOTES_PER_REQUEST)
				dispatch({type: NOTES_LOADED_ALL})

		})
		.catch(e => console.log(e.message))
		.finally(() => dispatch({type: NOTES_LOADING, data: false}))


}


/************************************************
* Method for ceate new order
* @param {string} owner
* @param {string} qualificationId 
* @param {string} price 
************************************************/
export const createOrder = (owner, qualificationId, price) => async (dispatch, getState) => {

	const {user} = getState()

	if (user.loading || !checkUserAuth())
		return false

 	owner	 					= owner.trim()


	// validate filds
	if (!owner || !qualificationId || isNaN(price)) {
		showMessage('All fields are required!')
		return false
	}

	dispatch(setLoading(true))

	let batch = db.batch()

	let candidateRef 	= store.candidates.doc(owner)
	let orderRef 			= store.orders.doc()

	batch.update(candidateRef, {qualifications: fieldValue.arrayUnion(qualificationId)})

	batch.set(orderRef, {
		objectId: orderRef.id,
		price: parseFloat(price),
		createdAt: serverTime,
		qualificationId,
		owner,
		paid: 0,
		expenses: 0,
	})


	// start transaction
	let process =  new Promise((resolve, reject) => batch.commit()
	.then(() => {
		dispatch({
			type: CANDIDATES_ADD_QUALIFICATION,
			data : {
				objectId: owner,
				qualification: qualificationId
			}
		})

		return resolve('Order created successfully')
	})
	.catch(e => reject(e.message)))
	.finally(() => dispatch(setLoading(false)))

	await new Promise((resolve, reject) => resolve(showPromiseMessage(process, 'Order created successfully')))

	return true

}

/************************************************
* Method for create payment
* @param {string} orderId
* @param {string} type
* @param {number} amount
* @param {string} method
* @param {date} createdAt
* @param {string} owner
************************************************/

export const createPayment = (orderId, type, amount, method, createdAt, owner) => async (dispatch, getState) => {

	const {user, orders} = getState()

	if (user.loading || !checkUserAuth())
		return false

	// validate filds
	if (!type || !orderId || isNaN(amount) || !method) {
		showMessage('All fields are required!')
		return false
	}
	 
	dispatch(setLoading(true))

	amount = parseFloat(amount)

	let process = new Promise((resolve, reject) =>  db.runTransaction(transaction => {

			// create user ref
			const orderRef = store.orders.doc(orderId)

			// get user document
			return transaction.get(orderRef).then(orderDoc => {

				// get data
				let order = orderDoc.data() || {}

				if (type === 'payment' && ((order.price - order.paid) === 0 )) 
					return reject(`Qualification it's fully paid`)

				if (type === 'payment' && (order.price < (order.paid + amount))) 
					return reject(`The amount it's bigger then the payment due, ${formatToLocalCurrency(order.price - order.paid)} left to pay`)
				

				if (type === 'payment')
					transaction.update(orderRef, {paid: fieldValue.increment(amount)})
				else
					transaction.update(orderRef, {expenses: fieldValue.increment(amount)})	

				const paymetRef = store.payments.doc()

				transaction.set(paymetRef,{
					objectId: paymetRef.id,
					amount,
					method,
					type,
					orderId,
					createdAt,
					owner
				})
				
				return resolve()

			})
		
	}))
	.then(() => {

		let payload = {
			objectId: orderId,
		}

		let order = orders.list.find(item => item.objectId === orderId) || {}

		if (type === 'payment')
			payload.paid = order.paid + amount
		else
			payload.expenses = order.expenses + amount

		dispatch({
			type: ORDERS_UPDATE_ITEM,
			data: payload
		})
	})

	await showPromiseMessage(process, 'Payment created succesfuly')

	dispatch(setLoading(false))

	return true


}


/************************************************
* Method for fetch orders
* @param {string} cid 
************************************************/

export const fetchOrders = cid => async (dispatch, getState) => {

	const {orders} = getState()

	if (orders.loading)
		return false

	dispatch({
		type: ORDERS_LOADING,
		data: true
	})	

	let query = store.orders
	.where('owner', '==', cid)
	.orderBy('createdAt', 'desc')


	if (unsubscribe.orders)
		unsubscribe.orders()

	await query.get()
	.then(snapshot => {

		let items = snapshot.docs.map(item => item.data())

		dispatch({
			type: ORDERS_LOADED,
			data: items
		})

		if (!snapshot.empty)
			query = query.endBefore(snapshot.docs[0])

			unsubscribe.orders = query.onSnapshot(snapshot =>
				snapshot.docChanges().forEach(change => {			

					if (change.type === 'added')
						dispatch({
							type: ORDERS_NEW_ITEM,
							data: change.doc.data({ serverTimestamps: 'estimate'})
						})
				})

			)


	})
	.catch(e => console.log(e.message))
	.finally(() => 	dispatch({
		type: ORDERS_LOADING,
		data: false
	}))

}


/************************************************
* Method for fetch orders
* @param {string} cid 
************************************************/

export const fetchPayments = cid => async (dispatch, getState) => {

	const {payments} = getState()

	if (payments.loading)
		return false

	dispatch({
		type: PAYMENTS_LOADING,
		data: true
	})	

	let query = store.payments
	.where('owner', '==', cid)
	.orderBy('createdAt', 'desc')


	if (unsubscribe.payments)
		unsubscribe.payments()

	await query.get()
	.then(snapshot => {

		let items = snapshot.docs.map(item => item.data())

		dispatch({
			type: PAYMENTS_LOADED,
			data: items
		})

		if (!snapshot.empty)
			query = query.endBefore(snapshot.docs[0])

			unsubscribe.payments = query.onSnapshot(snapshot =>
				snapshot.docChanges().forEach(change => {			

					if (change.type === 'added')
						dispatch({
							type: PAYMENTS_NEW_ITEM,
							data: change.doc.data({ serverTimestamps: 'estimate'})
						})
				})

			)


	})
	.catch(e => console.log(e.message))
	.finally(() => 	dispatch({
		type: PAYMENTS_LOADING,
		data: false
	}))

}

/************************************************
* Method for fetching reports
* @param {date} from
 * @param {date} to
************************************************/

export const fetchReports = (from, to) => async (dispatch, getState) => {

	const {user} = getState()

	if (user.loading)
		return false

	dispatch({
		type: USER_LOADING,
		data: true
	})	

	let start = moment(from).startOf('day').toDate()
	let end =  moment(to).endOf('day').toDate()

	let payments = await store.payments
	.where('createdAt', '>=', start)
	.where('createdAt', '<=', end)
	.orderBy('createdAt', 'asc')
	.get()
	.then(snapshot =>  snapshot.docs.map(item => item.data()))
	.catch(e => showMessage(e.message))
	
	let profit = 0 
	let expenses = 0
	let profitsTransactions = 0
	let expensesTransactions = 0
	let transactions = payments.length
	let list = []

	payments.map(item => {


		let date = moment(item.createdAt.toMillis()).format('DD MMM')

	 	let index = list.findIndex(i => i.date === date)

		if (index < 0) 
			list.push({
				expenses: 0,
				profit: 0,
				transactions: 0,
				profitsTransactions: 0,
				expensesTransactions: 0,
				date: date
			})

		index = list.findIndex(i => i.date === date)	

		if (item.type === 'payment') {
			profit +=  item.amount
			list[index].profit += item.amount
			list[index].profitsTransactions += 1
			profitsTransactions++
		}

		if (item.type === 'expenses') {
			expenses +=  item.amount
			list[index].expenses += item.amount
			list[index].expensesTransactions += 1
			expensesTransactions++
		}

		return list[index].transactions += 1

	})

	dispatch({
		type: USER_LOADING,
		data: false
	})	

	return {
		profit: profit,
		expenses: expenses,
		transactions: transactions,
		profitsTransactions: profitsTransactions,
		expensesTransactions: expensesTransactions,
		chart: list
	}

}


/************************************************
* Method for fetching reports
* @param {date} from
* @param {date} to
************************************************/

export const fetchExportDate = (from, to, qualifications) => async (dispatch, getState) => {

	const {user} = getState()

	if (user.loading)
		return false

	dispatch({
		type: USER_LOADING,
		data: true
	})	
	

	qualifications = qualifications.map(item => item.value)

	let start = from ? moment(from).startOf('day').toDate() : null
	let end = to ? moment(to).endOf('day').toDate() : null

	let query =  store.candidates

	if (start !== null)
		query = query.where('createdAt', '>=', start)

	if (end !== null)	
		query = query.where('createdAt', '<=', end)

	if (qualifications.length)
		query = query.where('qualifications', 'array-contains-any', qualifications)

	let candidates = await query
	.orderBy('createdAt', 'asc')
	.get()
	.then(snapshot =>  snapshot.docs.map(item => item.data()))
	.catch(e => console.log(e.message))
	

	dispatch({
		type: USER_LOADING,
		data: false
	})	

	return candidates

}


export const migrateCandidates = () => dispatch => {

	db.collection('Users').get().then(async snapshot => {

		let items = snapshot.docs.map(i => i.data())

		let process = items.map(  item => {

			let birthDate = item.dateOfBirth || ''

			if (birthDate) {
				birthDate = birthDate.split('/')
				birthDate = moment(`${birthDate[2]}-${birthDate[1]}-${birthDate[0]}`)
			}			

			let ref = store.candidates.doc()

			let payload = {
				createdAt: item.createdAt || serverTime,
				objectId: ref.id,
				firstName: item.firstName,
				lastName: item.lastName,
				middleName: item.middleName,
				birthDate: birthDate.format('YYYY-MM-DD'),
				phone: item.phoneNumber,
				email: item.email || '',
				clientStatus: item.status || '',
				applicationStatus: item.files ? (item.files.application?.status || '' ): '',
				documentStatus: item.files? (item.files.documents?.status || '') : '',
				photoStatus: item.files? (item.files.photos?.status || '') :  '',
				videoStatus: item.files? (item.files.videos?.status || '') : '',
				intermediar: item.intermediar || '',
				createdBy: item.createdBy || '',
				address: item.address || '', 
				postCode: item.postCode || '',
				houseNo: item.houseNumber || '',
				cscs: item.cscs || '',
				nino: item.nino || '',
				qualifications: []
			}

			return ref.set(payload)

			
		})

		await Promise.all(process)


	})

}


/************************************************
* Method for fetch users
************************************************/
export const fetchUsers = () => async (dispatch, getState) => {

	const {users} = getState()

	if (users.loading)
		return false

	dispatch({
		type: USERS_LOADING,
		data: true
	})	

	let query = store.users.orderBy('createdAt', 'desc')

	await query.get()
	.then(snapshot => {

		let items = snapshot.docs.map(item => item.data())

		dispatch({
			type: USERS_LOADED,
			data: items
		})

		if (!snapshot.empty)
			query = query.endBefore(snapshot.docs[0])

			unsubscribe.users = query.onSnapshot(snapshot =>
				snapshot.docChanges().forEach(change => {			

					if (change.type === 'added')
						dispatch({
							type: USERS_NEW_ITEM,
							data: change.doc.data({ serverTimestamps: 'estimate'})
						})
				})

			)


	}).catch(e => showMessage(e.message))

	dispatch({
		type: USERS_LOADING,
		data: false
	})	

}
