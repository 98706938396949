import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
import {fetchNotes} from '../../../actions/user'
import {Link} from 'react-router-dom'
import moment from 'moment'


export default function Notes(props) {

	const users = useSelector(state => state.users)
	const notes = useSelector(state => state.notes)
	const candidates = useSelector(state => state.candidates)
	const dispatch = useDispatch()

	const [list, setList] = useState(notes.list)
	const [sortCriteria, setSortCriteria] = useState('')
	const [sortType, setSortType] = useState('asc')

	const onSort = (criteria) => {
		
		setSortCriteria(criteria)
		
		if (sortCriteria === criteria)
			setSortType(sortType === 'asc' ? 'desc' : 'asc')
		else 
			setSortType('asc')

	}

	const onLoadMore = () => dispatch(fetchNotes())

	// UPDATE LIST
	useEffect(() => {
		setList(notes.list)
	}, [notes.list])


	useEffect(() => {
		dispatch(fetchNotes(true))
	},[dispatch])


	// SORT LIST ON CHANGE CRITERIA OR TYPE
	useEffect(() => {

		let sortList = [...list]

		if (sortType === 'asc') 
			sortList = sortList.sort((a,b) => (a[sortCriteria] > b[sortCriteria]) ? 1 : ((b[sortCriteria] > a[sortCriteria] ? -1 : 0)))
		 else 
			sortList = sortList.sort((a,b) => (a[sortCriteria] < b[sortCriteria]) ? 1 : ((b[sortCriteria] < a[sortCriteria] ? -1 : 0)))
		
		
		setList(sortList)	

		// eslint-disable-next-line
	},[sortCriteria, sortType])



	const createdByClass = classnames({
		'fad fa-sort': sortCriteria !== 'authorId',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'authorId',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'authorId'
	})

	const createdAtClass = classnames({
		'fad fa-sort': sortCriteria !== 'createdAt',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'createdAt',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'createdAt'
	})

	return(
		<div className="box p-2">
			<div className="candidates-list">

				<table className="table table-striped table-hover">
					<thead>
						<tr>
							<td>Message</td>
							<td>Candidate</td>
							<td onClick={() => onSort('authorId')}><i className={createdByClass}/>Created by</td>
							<td className="text-end" onClick={() => onSort('createdAt')}><i className={createdAtClass}/>Created at</td>
						</tr>
					</thead>
						
					<tbody>

						{list.map(item => {
							
							// let names = splitName(item.name)

							const statusClass = classnames({
								'align-middle': true,
								'info-badge': item.status === 'info',
								'success-badge': item.status === 'completed',
								'warning-badge': item.status === 'pending',
								'danger-badge': item.status === 'important',
							})

							let user = users.list.find(i => i.objectId === item.authorId) || {}
							let candidate = candidates.list.find(i => i.objectId === item.userId) || {}

							return <tr key={item.objectId} className={statusClass}>
								
								<td>{item.message}</td>
								<td><Link to={`/candidates/${item.userId}`}>{candidate.firstName} {candidate.lastName} </Link></td>
								<td>{user.name}</td>
								<td className="text-end">{moment(item.createdAt?.toMillis()).format('DD MMM YYYY HH:mm a')}</td>
							</tr>

						})}
					
					</tbody>
					
					<tfoot>
					{!notes.noMoreToLoad &&
						<tr className="text-center">
							<td colSpan="4" className="text-center">
							<button className="btn text-primary" onClick={onLoadMore} disabled={notes.loading}>
								{notes.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'load more' }
							</button>
							</td>
						</tr>
					}
					</tfoot>

				</table>

				{!notes.list.length && <div className="text-danger">
					No notes found
				</div>}


				</div>

		</div>
	)

}