import React,{useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router'
import {login} from '../../../actions/user'


export default function Login( props ) {

	const dispatch = useDispatch()
	const history = useHistory()
	const passwordInput = useRef()

	const user = useSelector( state => state.user )
	
	const [email, setEmail] = useState('')
	const [password, setPasswrod] = useState('')

	const onLogin = () => dispatch(login(email, password))

	const onKeyDown = e => {

		if (e.which !== 13 || !email.trim().length) 
			return false

		if (!password.trim().length) 
			return passwordInput.current.focus()

		return onLogin()

	}

	useEffect(() => {
		if (user.objectId)
			return history.push('/')
	},[user.objectId, history])

	return(
		<div className="container-fluid">
			<div className="connect text-center p-3">
			
				<h1 className="mb-5">Welcome</h1>

				<input
					className="form-control mb-2"
					placeholder="Email"
					type="email"
					name="email"
					onChange={e => setEmail( e.target.value )}
					autoComplete="off"
					onKeyDown={onKeyDown}
				/>

				<input
					className="form-control mb-3"
					placeholder="Password"
					type="password"
					name="password"
					onChange={e => setPasswrod( e.target.value )}
					autoComplete="new-password"
					onKeyDown={onKeyDown}
					ref={passwordInput}
				/>

				
				<div className="d-grid">
					<button onClick={onLogin} className="btn btn-primary" disabled={user.loading || !email.length || !password.length} >
						{user.loading ? <span className="spinner-border spinner-border-sm" /> : 'Login'}
					</button>
				</div>

			</div>
		</div>
	)

}