import React from 'react'
import {Switch, Route} from 'react-router-dom'

import Login from './Login'


export default function Conenct(props) {

	return(
		<div className="connect-page">
			
			<div className="wrapper">

				<div className="logo">
					<img src="assets/img/logo.png" alt="" />
				</div>

				<Switch>
					<Route exact path={['/']} component={Login} />
				</Switch>

			</div>
	
		</div>
	)

}