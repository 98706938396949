/************************************************
 * IMPORTS
 ***********************************************/
import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunk from 'redux-thunk'

import qualifications from './qualifications'
import candidates from './candidates'
import payments from './payments'
import orders from './orders'
import notes from './notes'
import users from './users'
import user from './user'


/************************************************
 * REDUCERS
 ***********************************************/

const reducres = {
	qualifications,
	candidates,
	payments,
	orders,
	notes,
	users,
	user,
}
 
/************************************************
 * STORE
 ***********************************************/
 
export default createStore(
	combineReducers(reducres),
	applyMiddleware(thunk)
)