export const TITLE = 'QMB CONSULTING'
export const DOMAIN = 'aztraining.co.uk'
export const API_URL = 'http://104.248.95.229'
export const CLIENTS_URL = `http://clients.${DOMAIN}`
export const POSTCODE_API_URL = 'https://api.postcodes.io/postcodes/'



export const VALIDATOR = {
	name: {
		length: [ 3, 30 ],
		exp: 		RegExp(/^(?=.{3,30}$)[a-zA-Z0-9\s]+$/)
	},
	legalName: {
		length: [ 2, 20 ],
		exp: 		RegExp(/^(?=.{2,30}$)[a-zA-Z\s]+$/)
	},
	email: {
		length: [ 5, 40 ],
		exp: 		RegExp(/^(?=.{5,40}$)[\w.-]+@[\w.-]+\.\w{2,4}$/)
	},
	username: {
		length: [ 4, 20 ],
		exp: 		RegExp(/^(?=.{4,20}$)[a-z0-9][a-z0-9_]+[a-z0-9]$/)
	},
	age: {
		value: 	[ 18, 75 ]
	},
	location: {
		length: [ 2, 30 ],
		exp: 		RegExp(/^(?=.{4,30}$)[a-zA-Z\s]+$/)
	},
	password: {
		length: [ 6, 30 ],
		exp: 		RegExp(/^.{6,30}$/)
	},
	number: {
		value: 	[ 1.99, 99 ],
		exp: 		RegExp(/^\$?\d+(\.\d+)?$/)
	},
	
	phoneNumber: {
		exp: RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/)
	}
}

export const MIME_TYPES = {
	'text/plain':																																			'txt',
	'image/png': 																																			'png',
	'image/jpeg': 																																		'jpg',
	'audio/wav': 																																			'wav',
	'video/mp4': 																																			'mp4',
	'video/quicktime': 																																'mov',
	'application/pdf':																																'pdf',
	'application/vnd.ms-excel': 																											'xls',
	'application/vnd.ms-powerpoint': 																									'ppt',
	'application/msword': 																														'doc',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': 			'pptx',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 							'xlsx',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 				'docx'
}

export const STORAGE_ERRORS = {
	'storage/unauthorized': 'Not enough rights. Refresh and retry.',
	'storage/canceled': 'Upload has been cancelled. Try again.',
	'storage/unknown': 'Unknown error occurred. Try again'
}

export const API_RESPONSE = {

}

export const SEARCH_FIELDS = {
	firstName: 'First Name',
	lastName: 'Last Name',
	phone: 'Phone',
	intermediar: 'Intermediar',
	clientStatus: 'Status'
}

export const STATUS = {
	missing: 'Missing',
	pending: 'Pending',
	completed: 'Completed'
}

export const COMMENT_PROPRITY = {
	info: 'Info',
	important: 'Important',
	pending: 'Pending',
	completed: 'Completed'
}

export const PAYMENT_METHOD = {
	cash: 'Cash',
	card: 'Card',
	transfer: 'Transfer'
}

export const PAYMENT_TYPE = {
	payment: 'Payment',
	expenses: 'Expenses',
}

export const EXPORT_FIELDS = {
	firstName: 'First Name',
	middleName: 'Middle Name',
	lastName: 'Last Name',
	birthDate: 'Birth Date',
	createdAt: 'Created At',
	email: 'Email',
	phone: 'Phone Number',
	intermediar: 'Intermediar',
	nino: 'NINO',
	cscs: 'CSCS',
	address: 'Address',
	houseNo: 'House Number',
	postCode: 'Post Code',
	qualifications: 'Qualifications',
}


// REQUEST LEGHTS
export const CANDIDATES_PER_REQUEST 			= 10
export const NOTES_PER_REQUEST						= 10


// REDUX REDUCERS TYPE
export const USERS												= 'users'
export const USER_UPDATE									= 'USER_UPDATE'
export const USER_LOADING									= 'USER_LOADING'
export const USER_LOGGED_OUT							= 'USER_LOGGED_OUT'


export const CANDIDATES										= 'candidates'
export const CANDIDATES_CLEAR							= 'CANDIDATES_CLEAR'
export const CANDIDATES_LOADED						= 'CANDIDATES_LOADED'
export const CANDIDATES_LOADING						= 'CANDIDATES_LOADING'
export const CANDIDATES_UPDATE						= 'CANDIDATES_UPDATE'
export const CANDIDATES_NEW_ITEM					= 'CANDIDATES_NEW_ITEM'
export const CANDIDATES_REMOVE_ITEM				= 'CANDIDATES_REMOVE_ITEM'
export const CANDIDATES_UPDATE_ITEM				= 'CANDIDATES_UPDATE_ITEM'
export const CANDIDATES_LOADED_MORE				= 'CANDIDATES_LOADED_MORE'
export const CANDIDATES_LOADED_ALL				= 'CANDIDATES_LOADED_ALL'
export const CANDIDATES_CLEAR_FILTER			= 'CANDIDATES_CLEAR_FILTER'
export const CANDIDATES_UPDATE_FILTER			= 'CANDIDATES_UPDATE_FILTER'
export const CANDIDATES_ADD_QUALIFICATION	= 'CANDIDATES_ADD_QUALIFICATION'


export const QUALIFICATIONS								= 'qualifications'
export const QUALIFICATIONS_CLEAR					= 'QUALIFICATIONS_CLEAR'
export const QUALIFICATIONS_LOADED				= 'QUALIFICATIONS_LOADED'
export const QUALIFICATIONS_LOADING				= 'QUALIFICATIONS_LOADING'
export const QUALIFICATIONS_UPDATE_ITEM		= 'QUALIFICATIONS_UPDATE_ITEM'
export const QUALIFICATIONS_NEW_ITEM			= 'QUALIFICATIONS_NEW_ITEM'
export const QUALIFICATIONS_REMOVE_ITEM		= 'QUALIFICATIONS_REMOVE_ITEM'


export const NOTES												= 'notes'
export const NOTES_CLEAR									= 'NOTES_CLEAR'
export const NOTES_LOADED									= 'NOTES_LOADED'
export const NOTES_LOADING								= 'NOTES_LOADING'
export const NOTES_UPDATE									= 'NOTES_UPDATE'
export const NOTES_NEW_ITEM								= 'NOTES_NEW_ITEM'
export const NOTES_REMOVE_ITEM						= 'NOTES_REMOVE_ITEM'
export const NOTES_UPDATE_ITEM						= 'NOTES_UPDATE_ITEM'
export const NOTES_LOADED_MORE						= 'NOTES_LOADED_MORE'
export const NOTES_LOADED_ALL							= 'NOTES_LOADED_ALL'

export const ORDERS												= 'orders'
export const ORDERS_CLEAR									= 'ORDERS_CLEAR'
export const ORDERS_LOADED								= 'ORDERS_LOADED'
export const ORDERS_LOADING								= 'ORDERS_LOADING'
export const ORDERS_UPDATE_ITEM						= 'ORDERS_UPDATE_ITEM'
export const ORDERS_NEW_ITEM							= 'ORDERS_NEW_ITEM'
export const ORDERS_REMOVE_ITEM						= 'ORDERS_REMOVE_ITEM'

export const PAYMENTS											= 'payments'
export const PAYMENTS_CLEAR								= 'PAYMENTS_CLEAR'
export const PAYMENTS_LOADED							= 'PAYMENTS_LOADED'
export const PAYMENTS_LOADING							= 'PAYMENTS_LOADING'
export const PAYMENTS_UPDATE_ITEM					= 'PAYMENTS_UPDATE_ITEM'
export const PAYMENTS_NEW_ITEM						= 'PAYMENTS_NEW_ITEM'
export const PAYMENTS_REMOVE_ITEM					= 'PAYMENTS_REMOVE_ITEM'


export const USERS_CLEAR									= 'USERS_CLEAR'
export const USERS_LOADED									= 'USERS_LOADED'
export const USERS_UPDATE									= 'USERS_UPDATE'
export const USERS_LOADING								= 'USERS_LOADING'
export const USERS_NEW_ITEM								= 'USERS_NEW_ITEM'