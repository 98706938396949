import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import classnames from 'classnames'
import moment from 'moment'
import {formatToLocalCurrency} from '../../../../../utils'
import {PAYMENT_METHOD} from '../../../../../constants'


export default function List(props) {


	const orders = useSelector(state => state.orders)
	const payments = useSelector(state => state.payments)
	const qualifications = useSelector(state => state.qualifications)


	const [list, setList] = useState(payments.list)
	const [sortCriteria, setSortCriteria] = useState('')
	const [sortType, setSortType] = useState('asc')


	const onSort = (criteria) => {
		
		setSortCriteria(criteria)
		
		if (sortCriteria === criteria)
			setSortType(sortType === 'asc' ? 'desc' : 'asc')
		else 
			setSortType('asc')

	}

	// UPDATE LIST
	useEffect(() => {
		setList(payments.list)
	}, [payments.list])


	// SORT LIST ON CHANGE CRITERIA OR TYPE
	useEffect(() => {

		let sortList = [...list]

		if (sortType === 'asc') 
			sortList = sortList.sort((a,b) => (a[sortCriteria] > b[sortCriteria]) ? 1 : ((b[sortCriteria] > a[sortCriteria] ? -1 : 0)))
		 else 
			sortList = sortList.sort((a,b) => (a[sortCriteria] < b[sortCriteria]) ? 1 : ((b[sortCriteria] < a[sortCriteria] ? -1 : 0)))
		
		
		setList(sortList)	

		// eslint-disable-next-line
	},[sortCriteria, sortType])


	const amountClass = classnames({
		'fad fa-sort': sortCriteria !== 'amount',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'amount',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'amount'
	})

	const titleClass = classnames({
		'fad fa-sort': sortCriteria !== 'orderId',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'orderId',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'orderId'
	})

	const methodClass = classnames({
		'fad fa-sort': sortCriteria !== 'method',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'method',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'method'
	})

	const createdAtClass = classnames({
		'fad fa-sort': sortCriteria !== 'createdAt',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'createdAt',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'createdAt'
	})



	return(

		<div className="box p-2 no-top">
			<div className="candidates-list">

				<table className="table table-striped table-hover">
					<thead>
						<tr>
							<td onClick={() => onSort('orderId')}><i className={titleClass}/>Qualification</td>
							<td onClick={() => onSort('amount')}><i className={amountClass}/>Amount</td>
							<td onClick={() => onSort('method')}><i className={methodClass}/>Method</td>
							<td onClick={() => onSort('createdAt')}><i className={createdAtClass}/>Created at</td>
						</tr>
					</thead>
						
					<tbody>

						{list.map(item => {
							
							// let names = splitName(item.name)
							let order = orders.list.find(i => i.objectId === item.orderId) || {}

							let qualification = qualifications.list.find(i => i.objectId === order.qualificationId) || {}
							
							const typeClass = classnames({
								'text-success': item.type === 'payment',
								'text-danger': item.type === 'expenses'
							})
			

							return <tr key={item.objectId} className='align-middle'>
								
								<td>{qualification.title}</td>
								<td className={typeClass}><b>{formatToLocalCurrency(item.amount)}</b></td>
								<td>{PAYMENT_METHOD[item.method]}</td>
								<td >{moment(item.createdAt?.toMillis()).format('DD MMM YYYY HH:mm a')}</td>
							</tr>

						})}
					
					</tbody>
					

				</table>

				{!payments.list.length && <div className="text-danger">
					No payments found
				</div>}


			</div>

		</div>

	)

}