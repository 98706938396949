import {
	USER_LOGGED_OUT,
	USER_LOADING,
	USER_UPDATE
} from '../constants'

const initialState = {
	loading: false,
}

export default function User(state = initialState, action) {

  switch( action.type ) {

		case USER_LOADING:
			return {
				...state,
				loading: action.data
			}

		case USER_UPDATE:
			return {
				...state,
				...action.data
			}

		case USER_LOGGED_OUT:
			return initialState	

    default:
			return state
  } 
}