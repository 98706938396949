import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {createQualification} from '../../../actions/user'

export default function Create(props) {

	const dispatch = useDispatch()
	const user = useSelector(state => state.user)

	const initalState = {
		price: '',
		title: '',
	}

	const [state, changeState] = useState(initalState)


	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onChange = e => setState({[e.target.name]: e.target.value})

	const onSubmit = async () => {

		let response = await dispatch(createQualification(state))

		if(response)
			setState(initalState)

	}

	return(
		<div className="row p-2">

			<div className="col-12 col-md-6">
				<div className="input-group mb-3">
					<span className="input-group-text">Title</span>
					<input 
						type="text" 
						className="form-control" 
						name="title"
						value={state.title}
						onChange={onChange}
					/>
				</div>
			</div>

			<div className="col-12 col-md-6">
				<div className="input-group mb-3">
					<span className="input-group-text">Price</span>
					<input 
						type="number" 
						className="form-control" 
						name="price"
						value={state.price}
						onChange={onChange}
					/>
				</div>
			</div>


			<div className="col-12 text-end">
				<button onClick={onSubmit} className="btn btn-success" disabled={user.loading}>
					{user.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'Create' }
				</button>
			</div>

		</div>
	)

}