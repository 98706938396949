import React,{useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'

import List from './List'
import Create from './Create'
import Payments from './Payments'

import {fetchOrders} from '../../../../actions/user'


export default function Candidates(props) {

	const dispatch = useDispatch()
	const {pid} = useParams()

	const [tab, setTab] = useState('list')

	useEffect(() => {
		dispatch(fetchOrders(pid))
	}, [dispatch, pid])

	return(
		<>
			<ul className="nav nav-tabs mt-3">

				<li className="nav-item" onClick={() => setTab('list')}>
					<div className={`nav-link ${tab === 'list' ? 'active' : ''}`} >Qualifications</div>
				</li>

				<li className="nav-item" onClick={() => setTab('create')}>
					<div className={`nav-link ${tab === 'create' ? 'active' : ''}`} >Add qualification</div>
				</li>

				<li className="nav-item" onClick={() => setTab('payments')}>
					<div className={`nav-link ${tab === 'payments' ? 'active' : ''}`} >Payments</div>
				</li>

			</ul>

			{tab === 'list' && <List />}
			{tab === 'create' && <Create />}
			{tab === 'payments' && <Payments />}

		</>
	)

}