import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
import moment from 'moment'
import {useParams} from 'react-router'
import {formatToLocalCurrency} from '../../../../../utils'
import DatePicker from "react-datepicker"


import Modal from '../../../../Modal'
import {PAYMENT_METHOD, PAYMENT_TYPE} from '../../../../../constants'
import { createPayment } from '../../../../../actions/user'


export default function List(props) {

	const {pid} = useParams()
	const dispatch = useDispatch()

	const initLog = {
		amount: '',
		paymentMethod: 'cash',
		paymentType: 'payment',
		createdAt: new Date()
	}

	const user = useSelector(state => state.user)
	const orders = useSelector(state => state.orders)
	const qualifications = useSelector(state => state.qualifications)


	const [list, setList] = useState(orders.list)
	const [sortCriteria, setSortCriteria] = useState('')
	const [sortType, setSortType] = useState('asc')
	const [modal, setModal] = useState(false)
	const [log, setLog] = useState(initLog)

	const setState = data => setLog(prevState => ({...prevState, ...data}))

	const onChange = e => setState({[e.target.name]: e.target.value})


	const onSort = (criteria) => {
		
		setSortCriteria(criteria)
		
		if (sortCriteria === criteria)
			setSortType(sortType === 'asc' ? 'desc' : 'asc')
		else 
			setSortType('asc')

	}

	const onShowModal = (display, log = initLog) => {
		
		log = {...log}

		log.paymentMethod = log.paymentMethod || 'cash'
		log.paymentType = log.paymentType || 'payment'
		log.amount = (log.price - log.paid) || 0
		log.createdAt = new Date()

		setModal(display)
		setLog(log)

	}

	const onSubmit = async () => {

		let response = await dispatch(createPayment(log.objectId, log.paymentType, log.amount, log.paymentMethod, log.createdAt, pid))

		if (!response)
			return false

		setLog(initLog)
		setModal(false)	

	}

	// UPDATE LIST
	useEffect(() => {
		setList(orders.list)
	}, [orders.list])


	// SORT LIST ON CHANGE CRITERIA OR TYPE
	useEffect(() => {

		let sortList = [...list]

		if (sortType === 'asc') 
			sortList = sortList.sort((a,b) => (a[sortCriteria] > b[sortCriteria]) ? 1 : ((b[sortCriteria] > a[sortCriteria] ? -1 : 0)))
		 else 
			sortList = sortList.sort((a,b) => (a[sortCriteria] < b[sortCriteria]) ? 1 : ((b[sortCriteria] < a[sortCriteria] ? -1 : 0)))
		
		
		setList(sortList)	

		// eslint-disable-next-line
	},[sortCriteria, sortType])


	const priceClass = classnames({
		'fad fa-sort': sortCriteria !== 'price',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'price',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'price'
	})

	const paidClass = classnames({
		'fad fa-sort': sortCriteria !== 'paid',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'paid',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'paid'
	})

	const expensesClass = classnames({
		'fad fa-sort': sortCriteria !== 'expenses',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'expenses',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'expenses'
	})

	const createdAtClass = classnames({
		'fad fa-sort': sortCriteria !== 'createdAt',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'createdAt',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'createdAt'
	})



	return(
		<>

			<div className="box p-2 no-top">
				<div className="candidates-list">

					<table className="table table-striped table-hover">
						<thead>
							<tr>
								<td>Qualification</td>
								<td onClick={() => onSort('price')}><i className={priceClass}/>Price</td>
								<td onClick={() => onSort('paid')}><i className={paidClass}/>Paid</td>
								<td onClick={() => onSort('expenses')}><i className={expensesClass}/>Expenses</td>
								<td onClick={() => onSort('createdAt')}><i className={createdAtClass}/>Created at</td>
								<td className="text-end"></td>
							</tr>
						</thead>
							
						<tbody>

							{list.map(item => {
								
								// let names = splitName(item.name)

								let qualification = qualifications.list.find(i => i.objectId === item.qualificationId) || {}

								const statusClass = classnames({
									'align-middle': true,
									'info-badge': item.status === 'info',
									'success-badge': item.status === 'completed',
									'warning-badge': item.status === 'pending',
									'danger-badge': item.status === 'important',
								})


								return <tr key={item.objectId} className={statusClass}>
									
									<td>{qualification.title}</td>
									<td className="text-warning">{formatToLocalCurrency(item.price)}</td>
									<td className="text-success">{formatToLocalCurrency(item.paid || 0)}</td>
									<td className="text-danger">{formatToLocalCurrency(item.expenses || 0)}</td>
									<td >{moment(item.createdAt?.toMillis()).format('DD MMM YYYY HH:mm a')}</td>
									<td className="text-end" onClick={() => onShowModal(true, {...item, title: qualification.title})}><i className="fad fa-money-check-edit-alt text-primary"/></td>
								</tr>

							})}
						
						</tbody>
						

					</table>

					{!orders.list.length && <div className="text-danger">
						No qualifications found
					</div>}


				</div>

			</div>


			{/* PAYMENT MODAL */}
			<Modal show={modal} onClose={()=> onShowModal(false)}>
				<div className="title">{log.title}</div>
					<div className="row p-2 players-form">

					<div className="col-12">
							<div className="input-group mb-3">
								<span className="input-group-text">Type</span>
								<select 
									type="text" 
									className="form-control" 
									name="paymentType"
									value={log.paymentType}
									onChange={onChange}
								>
									<option value=''>Select payment type</option>
									{Object.keys(PAYMENT_TYPE).map(key => <option key={key} value={key}>{PAYMENT_TYPE[key]}</option>)}
								</select>
							</div>
						</div>

						<div className="col-12 ">
							<div className="input-group mb-3">
								<span className="input-group-text">Amount</span>
								<input 
									type="number" 
									className="form-control" 
									name="amount"
									value={log.amount}
									onChange={onChange}
								/>
									<span className="input-group-text">GBP</span>
							</div>
						</div>

						<div className="col-12">
							<div className="input-group mb-3">
								<span className="input-group-text">Method</span>
								<select 
									type="text" 
									className="form-control" 
									name="paymentMethod"
									value={log.paymentMethod}
									onChange={onChange}
								>
									<option value=''>Select payment method</option>
									{Object.keys(PAYMENT_METHOD).map(key => <option key={key} value={key}>{PAYMENT_METHOD[key]}</option>)}
								</select>
							</div>
						</div>

						<div className="col-12">
							<div className="input-group mb-3 no-wrap">
								<span className="input-group-text">Date</span>
								<DatePicker 
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									selected={log.createdAt} 
									format="DD/MM/YYYY"
									dateFormat="dd MMM yyyy"
									customInput={<input className="form-control date-piker" />}
									onChange={(date) => setState({createdAt: date})} 
								/>
							</div>
						</div>
						
						<div className="col-12">
							<button className="btn btn-success fluid text-uppercase" onClick={onSubmit} disabled={user.loading}>
								{user.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'PAY' }
							</button>	
						</div>
				
				</div>

			</Modal>

		</>
	)

}