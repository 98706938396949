import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {formatPhone} from '../../../utils'
import classnames from 'classnames'
import {useHistory} from 'react-router-dom'
import { fetchCandidates } from '../../../actions/user'
import moment from 'moment'


export default function List(props) {

	const history = useHistory()

	const candidates = useSelector(state => state.candidates)
	const qualifications = useSelector(state => state.qualifications)
	const dispatch = useDispatch()

	const [list, setList] = useState(candidates.list)
	const [sortCriteria, setSortCriteria] = useState('')
	const [sortType, setSortType] = useState('asc')

	const onSort = (criteria) => {
		
		setSortCriteria(criteria)
		
		if (sortCriteria === criteria)
			setSortType(sortType === 'asc' ? 'desc' : 'asc')
		else 
			setSortType('asc')

	}

	const onLoadMore = () => dispatch(fetchCandidates())

	// UPDATE LIST
	useEffect(() => {
		setList(candidates.list)
		console.log(candidates.list)
	}, [candidates.list])


	// SORT LIST ON CHANGE CRITERIA OR TYPE
	useEffect(() => {

		let sortList = [...list]

		if (sortType === 'asc') 
			sortList = sortList.sort((a,b) => (a[sortCriteria] > b[sortCriteria]) ? 1 : ((b[sortCriteria] > a[sortCriteria] ? -1 : 0)))
		 else 
			sortList = sortList.sort((a,b) => (a[sortCriteria] < b[sortCriteria]) ? 1 : ((b[sortCriteria] < a[sortCriteria] ? -1 : 0)))
		
		
		setList(sortList)	

		// eslint-disable-next-line
	},[sortCriteria, sortType])

	// // SORT LIST ON CHANGE INPUT
	// useEffect(() => {

	// 	let sortList = [...candidates.list]

	// 	if (!search.length)
	// 		return 	setList(sortList)	

	// 	if(!isNaN(search))
	// 		sortList = sortList.filter(item => item.number === parseInt(search))
	// 	else
	// 		sortList = sortList.filter(item => item.name.includes(toTitleCase(search)))
		
	// 	setList(sortList)	

	// 	// eslint-disable-next-line
	// },[search])



	const nameClass = classnames({
		'fad fa-sort': sortCriteria !== 'lastName',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'lastName',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'lastName'
	})

	const createdAtClass = classnames({
		'fad fa-sort': sortCriteria !== 'createdAt',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'createdAt',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'createdAt'
	})



	return(
		<div className="box p-2 no-top">
			<div className="candidates-list">

				<table className="table table-striped table-hover">
					<thead className="thead-dark">
						<tr>
							<td onClick={() => onSort('lastName')}><i className={nameClass}/>Name</td>
							<td>Last Qualifications</td>
							<td>Phone</td>
							<td className="text-end" onClick={() => onSort('createdAt')}><i className={createdAtClass}/>Created at</td>
						</tr>
					</thead>
						
					<tbody>

						{list.map(item => {
							
							// let names = splitName(item.name)

							const statusClass = classnames({
								'align-middle': true,
								'success-badge': item.clientStatus === 'completed',
								'warning-badge': item.clientStatus === 'pending',
								'danger-badge': item.clientStatus === 'missing',
							})

							let lastQualificationId = item.qualifications[item.qualifications.length -1]
							let letQualification = qualifications.list.find(i => i.objectId === lastQualificationId) || {}

							return <tr key={item.objectId} className={statusClass}>
								
								<td onClick={() => history.push(`/candidates/${item.objectId}`)} ><b>{item.firstName}</b> <span className="text-muted">{item.lastName}</span></td>
								<td>{letQualification.title}</td>
								<td className="d-none d-md-table-cell phone">{item.phone ? <a href={`tel:${item.phone}`}>{formatPhone(item.phone)}</a> : 'N/A'}</td>
								<td className="d-md-none text-center phone">{item.phone ? <a href={`tel:${item.phone}`}><i className="fad fa-phone" /></a> : 'N/A'}</td>
								<td className="text-end">{moment(item.createdAt?.toMillis()).format('DD MMM YYYY HH:mm a')}</td>
							</tr>

						})}
					
					</tbody>
					
					<tfoot>
					{!candidates.noMoreToLoad &&
						<tr className="text-center">
							<td colSpan="4" className="text-center">
							<button className="btn text-primary" onClick={onLoadMore} disabled={candidates.loading}>
								{candidates.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'load more' }
							</button>
							</td>
						</tr>
					}
					</tfoot>

				</table>

				{!candidates.list.length && <div className="text-danger">
					No candidates found
				</div>}


			</div>

		</div>
	)

}