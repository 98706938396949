import React, {useEffect, useState} from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

import {useDispatch, useSelector} from 'react-redux'
import DatePicker from "react-datepicker"

import {fetchReports} from '../../actions/user'
// import {formatToLocalCurrency} from '../../utils'
import moment from 'moment';
import { formatToLocalCurrency } from '../../utils'
import Notes from './Notes'

export default function Home(props) {

	const dispatch = useDispatch()

	const user = useSelector(state => state.user)


	const [chart, setChart] 														= useState([])
	const [profit, setProfit] 													= useState(0)
	const [expenses, setExpenses] 											= useState(0)
	const [profitTransactions, setProfitTransactions] 	= useState(0)
	const [expensesTransactions, setExpensesTransactions] 	= useState(0)
	const [transactions, setTransactions] 							= useState(0)
	const [start, setStart] 														= useState(moment().add(-30, 'days').toDate())
	const [end, setEnd] 																= useState(moment().toDate())

	const onFetch = async () => {

		let response = 	await dispatch(fetchReports(start, end))

		setTransactions(response.transactions || 0)
		setProfitTransactions(response.profitsTransactions || 0)
		setExpensesTransactions(response.expensesTransactions || 0)
		setProfit(response.profit || 0)
		setExpenses(response.expenses || 0)
		setChart(response.chart || [])

	}

	useEffect(() => {

		onFetch()
	// eslint-disable-next-line
	},[dispatch])

	const CustomTooltip = ({ active, payload, label }) => {

		if (!payload)
			return null

		if (active) {
			return (
				<div className="custom-tooltip">
					<div className="text-success">
						<b className="toCapitalize">{payload[0].name}</b>
						<p className="label text-success">{`${formatToLocalCurrency(payload[0].value)} (${payload[0]?.payload?.profitsTransactions})`}</p>
					</div>

					<div className="text-danger">
						<b className="toCapitalize">{payload[1].name}</b>
						<p className="label text-danger">{`${formatToLocalCurrency(payload[1].value)} (${payload[1]?.payload?.expensesTransactions})`}</p>
					</div>
					
					<div className="text-warning">
						<b className="toCapitalize">Date</b>
						<p className="desc"><b>{label}</b></p>
					</div>
				
				</div>
			);
		}
	
		return null;
	};

	return(
		<div className="row">

			<div className="col-12 mb-3">

				<div className="box p-2">

					<div className="input-group no-wrap">
						<span className="input-group-text">From</span>
						<DatePicker 
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							selected={start} 
							maxDate={end}
							format="DD/MM/YYYY"
							dateFormat="dd MMM yyyy"
							customInput={<input className="form-control  no-round" />}
							onChange={(date) => setStart(date)} 
						/>

						<span className="input-group-text">To</span>

						<DatePicker 
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							selected={end} 
							minDate={start}
							format="DD/MM/YYYY"
							dateFormat="dd MMM yyyy"
							customInput={<input className="form-control no-round" />}
							onChange={(date) => setEnd(date)} 
						/>

						<button className="btn btn-outline-success" disabled={user.loading} onClick={onFetch}>
							{user.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'Fetch' }
						</button>

					</div>

				</div>

			</div>


			<div className="col-4 mb-3 ">
				<div className="box text-success p-2">
					Profit ({profitTransactions})
					<h1>{formatToLocalCurrency(profit)}</h1>
				</div>
			</div>

			<div className="col-4 mb-3 ">
				<div className="box text-danger p-2">
					Expensis ({expensesTransactions})
					<h1>{formatToLocalCurrency(expenses)}</h1>
				</div>
			</div>

			<div className="col-4 mb-3 ">
				<div className="box text-warning p-2">
					Transactions
					<h1>{transactions}</h1>
				</div>
			</div>

			{/* CHART */}
			<div className="col-12 mb-3">
					<div className="box p-2">

						<div className="chart-wrapper" style={{height: 300}}>
							<ResponsiveContainer width="100%" height={300}>

								<LineChart
									width={500}
									height={300}
									data={chart}
									margin={{
										top: 20,
										right: 30,
										left: 20,
										bottom: 20,
									}}
								>

								<CartesianGrid strokeDasharray="1 1" />
									<XAxis type="category"  dataKey="date"  />
									<YAxis type="number" />
									<Tooltip content={<CustomTooltip />} />
									<Legend />
									<Line dataKey="profit"  stroke="#82ca9d" strokeWidth="2" />
									<Line dataKey="expenses" stroke="#dc3545" strokeWidth="2"   />
								</LineChart>
							</ResponsiveContainer>

						</div>

					</div>
				</div>

				<div className="col-12">
					<Notes/>
				</div>

		</div>
	)

}