/************************************************
 * IMPORTS
 ***********************************************/

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/database'
import 'firebase/compat/firestore'


/************************************************
* INIT FIREBASE
***********************************************/

firebase.initializeApp({
	apiKey: "AIzaSyBKcT1vLbAHMgiQSMEaCYQWQ6PywoM72fU",
	authDomain: "azt-crm.firebaseapp.com",
	projectId: "azt-crm",
	storageBucket: "azt-crm.appspot.com",
	messagingSenderId: "194653652914",
	appId: "1:194653652914:web:89cfd7ff89b6d1d9b246ab"
})


/************************************************
* Firebase authentication instance
***********************************************/

export const auth = firebase.auth()


/************************************************
* Firebase database instance
***********************************************/

export const db = firebase.firestore()


/************************************************
* Firebase storage instance
***********************************************/

export const storage = firebase.storage()


/************************************************
* Firebase realtime instance
***********************************************/

export const realtime = firebase.database()


/************************************************
* Server timestamp placeholder
***********************************************/

export const serverTime = firebase.firestore.FieldValue.serverTimestamp()


/************************************************
* Create timestamp with client data
***********************************************/

export const clientTime = firebase.firestore.Timestamp


/************************************************
* Export field values to work with
***********************************************/

export const fieldValue = firebase.firestore.FieldValue