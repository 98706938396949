import {
	NOTES_CLEAR,
	NOTES_LOADED,
	NOTES_UPDATE,
	NOTES_LOADING,
	NOTES_NEW_ITEM,
	NOTES_UPDATE_ITEM,
	NOTES_REMOVE_ITEM,
	NOTES_LOADED_ALL,
	NOTES_LOADED_MORE
} from '../constants'

const initialState = {
	showModal: false,
	loading: false,
	list: [],
}

export default function Notes(state = initialState, action) {
	switch( action.type ) {

		case NOTES_UPDATE:
			return {
				...state,
				...action.data
			}

		case NOTES_LOADING:
			return {
				...state,
				loading: action.data
			}	

		case NOTES_LOADED:
			return {
				...state,
				loading: false,
				list: action.data || []
			}


		case NOTES_UPDATE_ITEM:
			return {
				...state,
				loading: false,
				list: state.list.map( i => i.objectId === action.data.objectId  ?  ({...i, ...action.data})  : i ) 
			}
			
	
		case NOTES_REMOVE_ITEM:
			return {
				...state,
				list: state.list.filter( i => i.objectId !== action.data ) 
		}
			
		case NOTES_NEW_ITEM:
			return {
				...state,
				loading: false,
				list: [action.data, ...state.list],
			}

		case NOTES_LOADED_MORE:
			return {
				...state,
				loading:false,
				list:[ ...state.list, ...action.data ]
			}	

		case NOTES_LOADED_ALL:
			return {
				...state,
				loading: false,
				noMoreToLoad: true
			}		

	
		case NOTES_CLEAR:
			return initialState  

		default:
			return state
	}
}