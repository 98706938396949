import React from 'react'
import {useDispatch} from 'react-redux'
import {logout} from '../../actions/user'

export default function TopBar(props) {

	const dispatch = useDispatch()

	return(
		<div className="box p-2 d-flex mb-3 align-items-center">

			<div className="separator" />

			<button className="btn btn-sm btn-danger" onClick={() => dispatch(logout())}>Logout</button>

		</div>
	)
}