import React, {useState, useRef} from 'react'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'

import {formatToLocalCurrency} from '../../../../../utils'

import {createOrder} from '../../../../../actions/user'


export default function Create(props) {

	const {pid} = useParams()
	const dispatch = useDispatch()
	const selectRef = useRef()

	const user = useSelector(state => state.user)
	const orders = useSelector(state => state.orders)
	const qualifications = useSelector(state => state.qualifications)

	const initalState = {
		value: '',
		title: '',
		price: '',
	}

	const [state, changeState] = useState(initalState)

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onChange = e => setState({[e.target.name]: e.target.value})

	const onSubmit = async () => {

		let response = await dispatch(createOrder(pid, state.value, state.price))

		if(!response)
			return false

		setState(initalState)
		selectRef.current.clearValue()

	}


	// useEffect(() => {
	// 	dispatch(fetchOrders(cid))
	// },[cid, dispatch])

	const formatOptionLabel = ({value, label, price}) =>{ 
		return	<div style={{ display: "flex" }}>
			<div>{label}</div>
			<div className="separator"/>
			<div className="select-badge text-success"><b>{formatToLocalCurrency(price)}</b></div>
		</div>
	}

	return(
		<div className="box p-2 no-top">

		<div className="row p-2">
			
			<div className="col-12 ">
				<Select 
					options={qualifications.list.map(item => ({value: item.objectId, label: item.title, price: item.price, title: item.title, isDisabled: orders.list.filter(i => i.qualificationId === item.objectId).length}))}
					formatOptionLabel={formatOptionLabel}
					onChange={e => setState(e || initalState)}
					className="mb-3"
					isClearable
					ref={selectRef}
				/>
			</div>

			{state.value && 
				<>

					<div className="col-12">
						<hr />
					</div>

					<div className="col-12 col-md-6">
						<div className="input-group mb-3">
							<span className="input-group-text">Price</span>
							<input 
								type="number" 
								className="form-control" 
								name="price"
								value={state.price}
								onChange={onChange}
							/>
						</div>
					</div>

					<div className="col-12 col-md-6 text-end">
						<button onClick={onSubmit} className="btn btn-outline-success" disabled={user.loading}>
							{user.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'Add' }
						</button>
					</div>
				
				</>
			}

		</div>

	</div>
	)

}