import React,{useState} from 'react'

import Create from './Create'
import Edit from './Edit'
import List from './List'

export default function Comments(props) {

	const [tab, setTab] = useState('list')
	const [item, setItem ] = useState({})

	const onEdit = item => {
		setItem(item)
		setTab('edit')
	}

	const onEditEnd = () => {
		setItem({})
		setTab('list')
	}

	return(
		<>

			<ul className="nav nav-tabs mt-3">

				<li className="nav-item" onClick={() => setTab('list')}>
					<div className={`nav-link ${tab === 'list' ? 'active' : ''}`}> Notes</div>
				</li>

				<li className="nav-item" onClick={() => setTab('create')}>
					<div className={`nav-link ${tab === 'create' ? 'active' : ''}`} >Add note</div>
				</li>

			</ul>


			{tab === 'list' && <List callBack={item => onEdit(item)}/>}
			{tab === 'create' && <Create callBack={() => setTab('list')} />}
			{tab === 'edit' && <Edit item={item} callBack={() => onEditEnd()}/>}
			

		</>
	)

}