import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import DatePicker from "react-datepicker"

import {
	updateCandidate
} from '../../../../actions/user'
import moment from 'moment'


export default function Personal(props) {

	const {pid} = useParams()
	const dispatch = useDispatch()

	const user 						= useSelector(state => state.user)
	const candidates 				= useSelector(state => state.candidates)

	const initalState = {
		objectId: '',
		id: '',
		firstName: '',
		lastName: '',
		middleName: '',
		birthDate: '',
		email: '',
		phone:  '',
	}

	const [state, changeState] = useState(initalState)

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onChange = e => setState({[e.target.name]: e.target.value})

	const onSubmit = async () => dispatch(updateCandidate(state))

	useEffect(() => {

		const candidate = candidates.list.find(item => item.objectId === pid) || {}

		if (!candidate.objectId)
			return false

		setState({
			objectId: candidate.objectId || '',
			firstName: candidate.firstName || '',
			lastName: candidate.lastName || '',
			middleName: candidate.middleName || '',
			birthDate: candidate.birthDate || '',
			email: candidate.email || '',
			phone: candidate.phone || '',
		})

	},[pid, candidates.list])



	return(
		<div className="box p-2 no-top">
			<div className="row p-2 players-form">


			<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">First Name</span>
						<input 
							type="text" 
							className="form-control" 
							name="firstName"
							value={state.firstName}
							onChange={onChange}
						/>
					</div>
				</div>


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Middle Name</span>
						<input 
							type="text" 
							className="form-control" 
							name="middleName"
							value={state.middleName}
							onChange={onChange}
						/>
					</div>
				</div>


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Last Name</span>
						<input 
							type="text" 
							className="form-control" 
							name="lastName"
							value={state.lastName}
							onChange={onChange}
						/>
					</div>
				</div>

				<div className="col-12 col-md-6">
					<div className="input-group mb-3 no-wrap">
						<span className="input-group-text">Birth date</span>
						<DatePicker 
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							selected={state.birthDate ? moment(state.birthDate).toDate() : ''} 
							format="DD/MM/YYYY"
							dateFormat="dd MMM yyyy"
							customInput={<input className="form-control date-piker" />}
							onChange={(date) => setState({birthDate: moment(date).format('YYYY-MM-DD')})} 
						/>
					</div>
				</div>


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Email</span>
						<input 
							type="text" 
							className="form-control" 
							name="email"
							value={state.email}
							onChange={onChange}
						/>
					</div>
				</div>


				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Phone</span>
						<input 
							type="text" 
							className="form-control" 
							name="phone"
							value={state.phone}
							onChange={onChange}
						/>
					</div>
				</div>


				<div className="col-12 text-end">
		
					<button onClick={onSubmit} className="btn btn-outline-success" disabled={user.loading}>
						{user.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'Save' }
					</button>
				</div>

			</div>
		</div>
	)

}