
import {
	ORDERS_CLEAR,
	ORDERS_LOADED,
	ORDERS_LOADING,
	ORDERS_NEW_ITEM,
	ORDERS_UPDATE_ITEM,
	ORDERS_REMOVE_ITEM,
} from '../constants'

const initialState = {
	loading: false,
	list: [],
}


export default function Orders(state = initialState, action) {
	switch(action.type) {

		case ORDERS_LOADING:
			return {
				...state,
				loading: action.data
			}

		case ORDERS_LOADED:
			return {
				...state,
				loading: false,
				list: action.data || []
			}

		case ORDERS_UPDATE_ITEM:
			return {
				...state,
				loading: false,
				list: state.list.map( i => i.objectId === action.data.objectId  ?  ({...i, ...action.data})  : i ) 
			}
			
	
		case ORDERS_REMOVE_ITEM:
			return {
				...state,
				list: state.list.filter( i => i.objectId !== action.data ) 
			}
			
		case ORDERS_NEW_ITEM:
			return {
				...state,
				loading: false,
				list: [action.data, ...state.list],
			}

	
		case ORDERS_CLEAR:
			return initialState  

		default:
			return state
	}
}