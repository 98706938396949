import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
	updateNote
} from '../../../../../actions/user'

import { 
	COMMENT_PROPRITY 
} from '../../../../../constants'


export default function Edit(props) {

	const dispatch = useDispatch()
	const {item} = props

	const user = useSelector(state => state.user)

	const initalState = {
		objectId: item?.objectId || '',
		message: item?.message || '',
		status: item?.status || 'info',
	}


	const [state, changeState] = useState(initalState)

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onChange = e => setState({[e.target.name]: e.target.value})

	const onSubmit = async () => {

		let response =  await dispatch(updateNote(state.objectId, state.status, state.message))

		if (response)
			props.callBack()

	}


	return(
		<div className="box p-2 no-top">
			<div className="row p-2 players-form">

			<div className="col-12">
					<div className="input-group mb-3">
						<textarea 
							type="text" 
							className="form-control" 
							name="message"
							rows={6}
							placeholder="type your comment here..."
							value={state.message}
							onChange={onChange}
						/>
					</div>
				</div>
			

				<div className="col-12 col-md-6">
					<div className="input-group mb-3">
						<span className="input-group-text">Status</span>
						<select 
							type="text" 
							className="form-control" 
							name="status"
							value={state.status}
							onChange={onChange}
						>
							{Object.keys(COMMENT_PROPRITY).map(key => <option key={key} value={key}>{COMMENT_PROPRITY[key]}</option>)}
						</select>	
					</div>
				</div>
		
		
				<div className="col-12 col-md-6 text-end">
					<button onClick={onSubmit} className="btn btn-outline-success" disabled={user.loading || !state.message}>
						{user.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'Update' }
					</button>
				</div>

			</div>
		</div>	
	)

}