import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import classnames from 'classnames'
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import { formatToLocalCurrency } from '../../../utils'



export default function List(props) {

	const history = useHistory()

	const qualifications = useSelector(state => state.qualifications)

	const [search, setSearch] = useState('')
	const [list, setList] = useState(qualifications.list)
	const [sortCriteria, setSortCriteria] = useState('')
	const [sortType, setSortType] = useState('asc')

	const onSort = (criteria) => {
		
		setSortCriteria(criteria)
		
		if (sortCriteria === criteria)
			setSortType(sortType === 'asc' ? 'desc' : 'asc')
		else 
			setSortType('asc')

	}


	const onClear = () => {
		setSearch('')
	}

	// UPDATE LIST
	useEffect(() => {
		setList(qualifications.list)
	}, [qualifications.list])


	// SORT LIST ON CHANGE CRITERIA OR TYPE
	useEffect(() => {

		let sortList = [...list]

		if (sortType === 'asc') 
			sortList = sortList.sort((a,b) => (a[sortCriteria] > b[sortCriteria]) ? 1 : ((b[sortCriteria] > a[sortCriteria] ? -1 : 0)))
		 else 
			sortList = sortList.sort((a,b) => (a[sortCriteria] < b[sortCriteria]) ? 1 : ((b[sortCriteria] < a[sortCriteria] ? -1 : 0)))
		
		
		setList(sortList)	

		// eslint-disable-next-line
	},[sortCriteria, sortType])

	
	// SORT LIST ON CHANGE INPUT
	useEffect(() => {

		let sortList = [...qualifications.list]

		if (!search.length)
			return 	setList(sortList)	

		sortList = sortList.filter(item => item.title.includes(search))
		
		setList(sortList)	

		// eslint-disable-next-line
	},[search])


	const nameClass = classnames({
		'fad fa-sort': sortCriteria !== 'title',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'title',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'title'
	})

	const priceClasss = classnames({
		'fad fa-sort': sortCriteria !== 'price',
		'fad fa-sort-up': sortType === 'asc' && sortCriteria === 'price',
		'fad fa-sort-down': sortType === 'desc' && sortCriteria === 'price'
	})


	return(
		<div className="box p-2 no-top">
			<div className="candidates-list">

			<div className="p-2">
					<div className="input-group mb-3">

						<input 
							type="text" 
							className="form-control" 
							placeholder="type here ..."
							value={search}
							onChange={e => setSearch(e.target.value)}
						/>
		
						<button className="btn btn-danger" onClick={onClear} disabled={qualifications.loading}>
							{qualifications.loading ? <div className="spinner-border spinner-border-sm" role="status"/> : 'Clear' }
						</button>

					</div>
				</div>

				<table className="table table-striped table-hover">
					<thead>
						<tr>
							<td onClick={() => onSort('title')}><i className={nameClass}/>Title</td>
							<td onClick={() => onSort('price')}><i className={priceClasss}/>Price</td>
							<td>Created At</td>
							<td></td>
						</tr>
					</thead>
						
					<tbody>

						{list.map(item => {
							
							return <tr key={item.objectId} className="align-middle">
								
								<td><b>{item.title}</b></td>
								<td className="text-success"><b>{formatToLocalCurrency(item.price)}</b></td>
								<td>{moment(item.createdAt.toMillis()).format('DD MMM YYYY')}</td>
								<td className="text-end text-primary" onClick={() => history.push(`/qualifications/${item.objectId}`)} ><i className="fad fa-edit" /></td>
							</tr>

						})}
					
					</tbody>
			
				</table>

				{!list.length && <div className="text-danger">
					No qualifications found
				</div>}


			</div>

		</div>
	)

}